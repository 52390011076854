import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import moment from 'moment';

import {
  selectIsFilterLoading,
  selectIsFilterError,
  fetchResellers,
  selectResellerList,
  selectFilter,
} from '../../components/DashboardFilter/dashboardFilterSlice';

import {
  selectIsLoading,
  selectIsError,
  selectSmartMeterId,
  selectDate,
  selectIncludeElectricReadings,
  selectData,
  selectErrorMessage,
  changeInput,
  fetchData,
  clearData,
} from './packetMessagesSlice';

import Loader from '../../components/Loader';
import { ErrorText, Text } from '../../components/typography';
import DashboardFilter from '../../components/DashboardFilter';
import PacketMessagesTable from './PacketMessagesTable';
import { EMPTY_RESELLER_OPTION, MESSAGES } from '../../utils/constants';
import PageTitle from '../../components/PageTitle';

import { GridContainer } from './styles';

function PacketMessages() {
  const resellerList = useSelector(selectResellerList);
  const isFilterLoading = useSelector(selectIsFilterLoading);
  const isFilterError = useSelector(selectIsFilterError);

  const filter = useSelector(selectFilter);
  const smartMeterId = useSelector(selectSmartMeterId);
  const date = useSelector(selectDate);
  const includeElectricReadings = useSelector(selectIncludeElectricReadings);

  const isLoading = useSelector(selectIsLoading);
  const isError = useSelector(selectIsError);
  const errorMessage = useSelector(selectErrorMessage);
  const data = useSelector(selectData);
  const dispatch = useDispatch();

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up('sm'));

  useEffect(() => {
    if (!resellerList) {
      dispatch(fetchResellers());
    }
  }, [dispatch, resellerList]);

  useEffect(() => {
    dispatch(clearData());
  }, [dispatch, clearData, filter]);

  useEffect(() => {
    if (smartMeterId && filter.reseller.integratorId) {
      handleSubmit();
    }
  }, []);

  const handleSmartMeterId = (event) => {
    dispatch(changeInput({ name: 'smartMeterId', value: event.target.value }));
  };

  const handleDate = (value) => {
    dispatch(changeInput({ name: 'date', value }));
  };

  const handleIncludeElectricReadings = (event, value) => {
    dispatch(changeInput({ name: 'includeElectricReadings', value }));
  };

  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }

    dispatch(
      fetchData({
        smartMeterId,
        integratorId: filter.reseller.integratorId,
        date: moment(date).format('YYYY-MM-DD'),
        includeElectricReadings,
      })
    );
  };

  return (
    <>
      {isFilterLoading && !isFilterError && <Loader data-testid="loader" />}

      {!isFilterLoading && isFilterError && (
        <ErrorText>{MESSAGES.SERVER_ERROR}</ErrorText>
      )}

      {!isFilterLoading && !isFilterError && resellerList && (
        <>
          <PageTitle title="Packet-based Messages Dashboard">
            <DashboardFilter showMonthPicker={false} showDeviceType={false} />
          </PageTitle>

          {filter.reseller && filter.reseller.name === EMPTY_RESELLER_OPTION ? (
            <Text>Please, select a reseller.</Text>
          ) : (
            <form onSubmit={handleSubmit}>
              <GridContainer container spacing={2}>
                <Grid item>
                  <TextField
                    id="smart-meter-id"
                    label="Smart meter ID"
                    value={smartMeterId}
                    onChange={handleSmartMeterId}
                    variant="standard"
                    style={{ width: sm ? '360px' : '100%' }}
                    autoFocus
                    type="search"
                  />
                </Grid>
                <Grid item>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      id="date-picker-inline"
                      label="Date"
                      value={date}
                      onChange={handleDate}
                      disableFuture
                      style={{ width: sm ? '110px' : '100%' }}
                      variant="standard"
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={includeElectricReadings}
                          onChange={handleIncludeElectricReadings}
                          name="includeElectricReadings"
                          color="primary"
                        />
                      }
                      label="Show electric readings"
                    />
                  </FormGroup>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={smartMeterId === ''}
                    style={{ width: sm ? 'inherit' : '100%' }}
                  >
                    Search
                  </Button>
                </Grid>
              </GridContainer>
            </form>
          )}

          {isLoading && !isError && <Loader data-testid="loader" />}

          {!isLoading && isError && <ErrorText>{errorMessage}</ErrorText>}
          
          {!isLoading && !isError && data && data.length === 0 && (
            <Text>{MESSAGES.NO_DATA}</Text>
          )}

          {!isLoading && !isError && data && data.length > 0 && (
            <PacketMessagesTable
              data={data}
              showElectricReadings={Boolean(data[0].electricReadings)}
            />
          )}
        </>
      )}
    </>
  );
}

export default PacketMessages;
