import styled from 'styled-components';
import { GoArrowUp, GoArrowDown } from 'react-icons/go';
import Paper from '@material-ui/core/Paper';

export const Wrapper = styled(Paper)`
  height: 365px;
  padding: 20px 20px 45px 20px;

  @media (max-width: 600px) {
    height: 320px;
  }

  & > h4 {
    font-weight: 400;
    margin: 0;
    margin-bottom: 10px;
  }
`;

export const Text = styled.foreignObject`
  color: #666;
  background: #eee;
  border-radius: 2px;
  padding: 2px 0;
  text-align: center;

  @-moz-document url-prefix() {
    color: #fff;
  }
`;

export const TooltipWrapper = styled.div`
  border: 1px solid #bbb;
  background: #fff;
  padding: 5px;

  display: flex;
  flex-direction: column;

  & > div {
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }

    > div {
      display: flex;
    }
  }
`;

export const TooltipPercentage = styled.span`
  display: block;
  margin-top: 5px;
  font-size: 0.9em;
  color: ${(props) => (props.isNegative ? '#ff0033' : '#6ab42d')};
`;

export const UpArrow = styled(GoArrowUp)`
  margin-left: -5px;
`;

export const DownArrow = styled(GoArrowDown)`
  margin-left: -5px;
`;

export const Box = styled.div`
  width: 15px;
  height: 10px;
  margin-right: 5px;
  background: ${(props) => props.fill};
  align-self: center;
`;
