import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectAuthorities } from '../pages/Login/loginSlice';
import { AUTHORITIES } from '../utils/constants';

const useAuthorities = () => {
  const authorities = useSelector(selectAuthorities);
  const isAdmin = useCallback(() => {
    if (!authorities) {
      return false;
    }
    return authorities.includes(AUTHORITIES.VOLTA_ADMIN);
  }, [authorities]);

  const isAdminOrVolta = useCallback(() => {
    if (!authorities) {
      return false;
    }
    return (
      authorities.includes(AUTHORITIES.VOLTA_ADMIN) ||
      authorities.includes(AUTHORITIES.VOLTA_USER)
    );
  }, [authorities]);

  const isVoltaAdminOrResellerAdmin = useCallback(() => {
    if (!authorities) {
      return false;
    }
    return (
      authorities.includes(AUTHORITIES.VOLTA_ADMIN) ||
      authorities.includes(AUTHORITIES.RESELLER_ADMIN)
    );
  }, [authorities]);

  return {
    isAdmin,
    isAdminOrVolta,
    isVoltaAdminOrResellerAdmin,
  };
};

export default useAuthorities;
