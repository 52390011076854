import PropTypes from 'prop-types';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { BsFillQuestionDiamondFill as InfoIcon } from 'react-icons/bs';
import ReactTooltip from 'react-tooltip';

const StyledPaper = styled(Paper)`
  min-height: 120px;
  padding: 15px 15px 10px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledData = styled(Typography)`
  color: #388e3c;
  align-self: center;
`;

const StyledBlank = styled.div`
  height: 23px;
`;

const StyledNone = styled.div`

`;

const StyledDetails = styled(Typography)`
  align-self: flex-end;
`;

function Card({ title, data, details = null, tooltip = null, ...props }) {
  return (
    <Grid item {...props}>
      <StyledPaper>
        <Typography>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            {title}
            {tooltip ? <InfoIcon fill="#388e3c" style={{marginLeft: '10px'}} data-tip data-for={tooltip}/> : <StyledNone/>}
          </div>
        </Typography>
        <StyledData variant="h4">{data}</StyledData>
        {details ? <StyledDetails>{details}</StyledDetails> : <StyledBlank />}
        {tooltip ? <ReactTooltip id={tooltip}><div>{tooltip}</div></ReactTooltip> : <StyledNone/>}
      </StyledPaper>
    </Grid>
  );
}

Card.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.string.isRequired,
  details: PropTypes.string,
  tooltip: PropTypes.string,
};

export default Card;
