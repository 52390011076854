import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

export const GridContainer = styled(Grid)`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: stretch;
  }
`;
