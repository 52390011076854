import { createSlice } from '@reduxjs/toolkit';
import API from '../../api';
import { AUTHORITIES, MESSAGES } from '../../utils/constants';

export const usersSlice = createSlice({
  name: 'users',
  initialState: {
    isLoading: false,
    isError: false,
    users: [],
    email: '',
    role: { admin: true, nonAdmin: false },
    validationMessage: '',
    errorMessage: MESSAGES.SERVER_ERROR,
  },
  reducers: {
    fetchStart: (state) => {
      state.isLoading = true;
      state.isError = false;
      state.validationMessage = '';
    },
    fetchSuccess: (state, action) => {
      state.isLoading = false;
      state.users = action.payload;
    },
    fetchError: (state) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = MESSAGES.SERVER_ERROR;
    },
    changeEmail: (state, action) => {
      state.email = action.payload;
    },
    changeCheckbox: (state, action) => {
      if (!action.payload.value) {
        return;
      }
      if (state.role.admin || state.role.nonAdmin) {
        state.role.admin = false;
        state.role.nonAdmin = false;
      }
      state.role[action.payload.name] = action.payload.value;
    },
    clearForm: (state) => {
      state.email = '';
      state.validationMessage = '';
    },
    addStart: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    addSuccess: (state, action) => {
      state.isLoading = false;
      state.users.unshift(action.payload);
      state.email = '';
    },
    addError: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      if (action.payload === AUTHORITIES.VOLTA_ADMIN) {
        state.errorMessage =
          'Could not add the user. Maybe it is already added as a reseller.';
      } else {
        state.errorMessage = MESSAGES.SERVER_ERROR;
      }
    },
    deleteStart: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    deleteSuccess: (state, action) => {
      state.isLoading = false;
      state.users = state.users.filter(
        (user) => user.username !== action.payload
      );
    },
    deleteError: (state) => {
      state.isLoading = false;
      state.isError = true;
    },
    setValidationMessage: (state, action) => {
      state.validationMessage = action.payload;
    },
  },
});

export const {
  fetchStart,
  fetchSuccess,
  fetchError,
  changeEmail,
  changeCheckbox,
  clearForm,
  addStart,
  addSuccess,
  addError,
  deleteStart,
  deleteSuccess,
  deleteError,
  setValidationMessage,
} = usersSlice.actions;

export const fetchUsers = () => async (dispatch) => {
  try {
    dispatch(fetchStart());

    const response = await API.USERS.GET();

    dispatch(fetchSuccess(response.data));
  } catch (err) {
    dispatch(fetchError());
  }
};

export const addUser = (email, authority) => async (dispatch) => {
  try {
    dispatch(addStart());

    const response = await API.USERS.POST(email, authority);

    dispatch(addSuccess(response.data));
  } catch (err) {
    dispatch(addError(authority));
  }
};

export const deleteUser = (email) => async (dispatch) => {
  try {
    dispatch(deleteStart());

    await API.USERS.DELETE(email);

    dispatch(deleteSuccess(email));
  } catch (err) {
    dispatch(deleteError());
  }
};

export const selectIsLoading = (state) => state.users.isLoading;
export const selectIsError = (state) => state.users.isError;
export const selectUsers = (state) => state.users.users;
export const selectEmail = (state) => state.users.email;
export const selectRole = (state) => state.users.role;
export const selectValidationMessage = (state) => state.users.validationMessage;
export const selectErrorMessage = (state) => state.users.errorMessage;

export default usersSlice.reducer;
