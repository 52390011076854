export const propertyTypeOptions = [
  { label: 'All', value: 'ALL' },
  { label: 'Home', value: 'HOME' },
  { label: 'Office', value: 'OFFICE' },
  { label: 'Commercial', value: 'COMMERCIAL' },
  { label: 'Industry', value: 'INDUSTRY' },
];

export const homeSizeMarks = [
  {
    value: 0,
    label: '0 ',
  },
  {
    value: 501,
    label: '500+',
  },
];

export const personAmountOptions = [
  { label: 'All', value: [0] },
  {
    label: '1 - 2 people',
    value: [1, 2],
  },
  { label: '3 - 5 people', value: [3, 5] },
  { label: '6 people +', value: [6] },
];

export const bedroomsAmountOptions = [
  { label: 'All', value: [0] },
  {
    label: '1 - 2 bedrooms',
    value: [1, 2],
  },
  { label: '3 - 5 bedrooms', value: [3, 5] },
  { label: '6 bedrooms +', value: [6] },
];

export const homeTypeOptions = [
  { label: 'All', value: 'ALL' },
  {
    label: 'Flat',
    value: 'FLAT',
  },
  { label: 'House', value: 'HOUSE' },
];

export const deviceTypeOptions = [
  { label: 'Voltaware Sensor (Default)', value: 'VOLTAWARE_SENSOR' },
  // { label: 'Low Resolution Meter', value: 'LOW_RESOLUTION_METER' },
  { label: 'High Resolution Meter', value: 'HIGH_RESOLUTION_METER' }
];
