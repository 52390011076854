import { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import MUIIconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import MUIPopover from '@material-ui/core/Popover';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { selectCurrentUser } from '../../../pages/Login/loginSlice';
import { parseRoleValueToLabel } from '../../../utils/roles';

const IconButton = styled(MUIIconButton)`
  padding: 0;
`;

const Popover = styled(MUIPopover)`
  overflow-y: hidden;
`;

const PopoverWrapper = styled(Grid)`
  width: 270px;
  padding: 10px;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ButtonGroup = styled(Grid)`
  display: flex;
  justify-content: flex-end;

  & > button:first-child {
    margin-right: 10px;
  }
`;

function UsersTable({ users, handleDeleteEmail, isVoltaAdminOrResellerAdmin }) {
  const [username, setUsername] = useState('');
  const currentUser = useSelector(selectCurrentUser);
  const [anchorEl, setAnchorEl] = useState(false);

  const handleOpenPopover = (event, username) => {
    setAnchorEl(event.currentTarget);
    setUsername(username);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const popoverBody = (
    <PopoverWrapper container>
      <Grid item>
        <Typography>
          Are you sure you want to delete <strong>{username}</strong>?
        </Typography>
      </Grid>
      <ButtonGroup item>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleDeleteEmail(username)}
        >
          Yes
        </Button>
        <Button variant="contained" onClick={handleClosePopover}>
          Cancel
        </Button>
      </ButtonGroup>
    </PopoverWrapper>
  );

  const props = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
  });
  const AnimatedTableContainer = animated(TableContainer);

  return (
    <AnimatedTableContainer component={Paper} style={props}>
      <Table aria-label="table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Email</TableCell>
            <TableCell align="left">Role</TableCell>
            {isVoltaAdminOrResellerAdmin() && (
              <TableCell align="center"></TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((row) => (
            <TableRow key={row.username}>
              <TableCell align="left">{row.username}</TableCell>
              <TableCell align="left">
                {row.authorities && parseRoleValueToLabel(row.authorities[0])}
              </TableCell>
              {isVoltaAdminOrResellerAdmin() && (
                <TableCell align="center">
                  {row.username === 'admin' || row.username === currentUser ? (
                    ''
                  ) : (
                    <IconButton
                      aria-label="delete"
                      onClick={(event) =>
                        handleOpenPopover(event, row.username)
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Popover
        id="delete-popover"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {popoverBody}
      </Popover>
    </AnimatedTableContainer>
  );
}

UsersTable.propTypes = {
  users: PropTypes.array.isRequired,
  handleDeleteEmail: PropTypes.func.isRequired,
  isVoltaAdminOrResellerAdmin: PropTypes.func.isRequired,
};

export default UsersTable;
