import axios from './setup';
import moment from 'moment';

export const URLS = {
  LOGIN: '/login',
  LOGOUT: '/logout',
  OVERVIEW: '/reports/disaggregation',
  REPORTED: '/reports/appliances-overview',
  DISAGGREGATED: '/reports/disaggregated/appliances',
  NON_DISAGGREGATED: '/reports/non-detected-appliances',
  DATA_QUALITY: '/reports/data-quality',
  SUBSCRIPTION: '/reports/devices/status',
  PACKET_MESSAGES: '/data-validation/smart-meter/:smartMeterId/packets',
  RESELLERS: {
    WITHOUT_INTEGRATION: '/resellers',
    WITH_INTEGRATION: '/resellers/withIntegration',
    INTEGRATE: '/resellers/:integratorId/integrate',
  },
  COST_CONSUMPTION: {
    SUMMARY: '/resellers/compare_cost_consumption/summary',
    FILE: '/resellers/compare_cost_consumption/file',
    FILE_UPLOAD: '/resellers/compare_cost_consumption',
  },
  USERS: { GET: '/users', POST: '/users', DELETE: '/users/:username' },
};

const login = (username, password) => {
  const loginEncoded = btoa(`${username.trim()}:${password.trim()}`);

  return axios.post(URLS.LOGIN, null, {
    headers: { Authorization: 'Basic ' + loginEncoded },
  });
};

const logout = () => {
  return axios.delete(URLS.LOGOUT);
};

const getOverview = ({
  date,
  integratorId,
  ninetyPercentConnected,
  appliances,
  propertyType,
  homeSize,
  personAmount,
  bedroomsAmount,
  homeType,
  deviceType,
}) => {
  const from = moment(date).clone().startOf('month').format('YYYY-MM-DD');
  const to = moment(date).clone().endOf('month').format('YYYY-MM-DD');
  let params = {
    integratorId,
  };

  if (!integratorId) {
    delete params.integratorId;
  }
  let fromSize,
    toSize,
    fromPersonAmount,
    toPersonAmount,
    fromBedrooms,
    toBedrooms;

  if (propertyType === 'ALL') {
    propertyType = undefined;
  }

  if (propertyType === 'HOME') {
    if (homeSize) {
      [fromSize, toSize] = homeSize;
    }
    if (personAmount) {
      [fromPersonAmount, toPersonAmount] = personAmount;
    }
    if (bedroomsAmount) {
      [fromBedrooms, toBedrooms] = bedroomsAmount;
    }
  } else {
    homeType = '';
  }

  if (toSize > 500) {
    toSize = undefined;
  }

  if (!homeType || homeType === 'ALL') {
    homeType = undefined;
  }

  const body = {
    from,
    to,
    ninetyPercentConnected,
    propertyType,
    fromSize,
    toSize,
    fromPersonAmount,
    toPersonAmount,
    fromBedrooms,
    toBedrooms,
    homeType,
    appliances,
    deviceType,
  };

  if (!deviceType) {
    delete body.deviceType;
  }

  return axios.put(URLS.OVERVIEW, body, {
    params,
  });
};

const getReported = (date, integratorId) => {
  let params = {
    yearMonth: date,
    integratorId,
  };

  if (!integratorId) {
    delete params.integratorId;
  }

  return axios.get(URLS.REPORTED, {
    params,
  });
};

const getDisaggregated = (date, integratorId, deviceType) => {
  let params = {
    yearMonth: date,
    integratorId,
    deviceType
  };

  if (!integratorId) {
    delete params.integratorId;
  }

  if (!deviceType) {
    delete params.deviceType;
  }

  return axios.get(URLS.DISAGGREGATED, {
    params,
  });
};

const getNonDisaggregated = (date, integratorId, ninetyPercentConnected, deviceType) => {
  let params = {
    yearMonth: date,
    integratorId,
    ninetyPercentConnected,
    deviceType,
  };

  if (!integratorId) {
    delete params.integratorId;
  }

  if (!deviceType) {
    delete params.deviceType;
  }

  return axios.get(URLS.NON_DISAGGREGATED, {
    params,
  });
};

const getDataQuality = (date, integratorId, deviceType) => {
  let params = {
    yearMonth: date,
    integratorId,
    deviceType,
  };

  if (!integratorId) {
    delete params.integratorId;
  }

  if (!deviceType) {
    delete params.deviceType;
  }

  return axios.get(URLS.DATA_QUALITY, {
    params,
  });
};

const getSubscription = ({ fromDate, toDate, integratorId, deviceType }) => {
  let params = {
    from: fromDate,
    to: toDate,
    integratorId,
    deviceType,
  };

  if (!integratorId) {
    delete params.integratorId;
  }

  if (!deviceType) {
    delete params.deviceType;
  }

  return axios.get(URLS.SUBSCRIPTION, {
    params,
  });
};

const getPacketMessages = ({
  smartMeterId,
  integratorId,
  date,
  includeElectricReadings,
}) => {
  let params = {
    integratorId,
    date,
    includeElectricalReadings: includeElectricReadings,
  };

  return axios.get(
    URLS.PACKET_MESSAGES.replace(':smartMeterId', smartMeterId),
    {
      params,
    }
  );
};

const getResellersWithIntegration = () => {
  return axios.get(URLS.RESELLERS.WITH_INTEGRATION);
};

const getResellersWithoutIntegration = () => {
  const params = {
    query: '',
  };

  return axios.get(URLS.RESELLERS.WITHOUT_INTEGRATION, { params });
};

const integrateReseller = (integratorId, email) => {
  return axios.post(
    URLS.RESELLERS.INTEGRATE.replace(':integratorId', integratorId),
    { email: email }
  );
};

const getUsers = () => {
  return axios.get(URLS.USERS.GET);
};

const addUser = (email, authority) => {
  return axios.post(URLS.USERS.POST, { email: email, authority: authority });
};

const deleteUser = (username) => {
  return axios.delete(URLS.USERS.DELETE.replace(':username', username));
};

const costConsumptionFileUpload = (data) => {
  return axios.post(
    URLS.COST_CONSUMPTION.FILE_UPLOAD, 
    data,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    },
  );
};

const costConsumptionSummary = (integratorId) => {
  let params = {
    ...(integratorId && { integratorId: integratorId }),
  };

  return axios.get(URLS.COST_CONSUMPTION.SUMMARY, { params });
};

const costConsumptionFile = ({ integratorId, fileName }) => {
  let params = {
    fileName: fileName,
    ...(integratorId && { integratorId: integratorId }),
  };

  return axios.get(URLS.COST_CONSUMPTION.FILE, { params });
};

const API = {
  LOGIN: login,
  LOGOUT: logout,
  OVERVIEW: getOverview,
  REPORTED: getReported,
  DISAGGREGATED: getDisaggregated,
  NON_DISAGGREGATED: getNonDisaggregated,
  DATA_QUALITY: getDataQuality,
  SUBSCRIPTION: getSubscription,
  PACKET_MESSAGES: getPacketMessages,
  RESELLERS: {
    WITHOUT_INTEGRATION: getResellersWithoutIntegration,
    WITH_INTEGRATION: getResellersWithIntegration,
    INTEGRATE: integrateReseller,
  },
  USERS: {
    GET: getUsers,
    POST: addUser,
    DELETE: deleteUser,
  },
  COST_CONSUMPTION: {
    SUMMARY: costConsumptionSummary,
    FILE: costConsumptionFile,
    FILE_UPLOAD: costConsumptionFileUpload,
  },
};

export default API;
