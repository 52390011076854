import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';

const Wrapper = styled.div`
  flex: 1;
  justify-self: center;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function Loader() {
  return (
    <Wrapper data-testid="loader">
      <CircularProgress />
    </Wrapper>
  );
}

export default Loader;
