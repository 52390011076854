import { useState, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
import Popover from '@material-ui/core/Popover';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import Loader from '../../components/Loader';
import { PopoverWrapper, FormControl } from './styles';
import { ErrorText } from '../../components/typography';
import UsersTable from './UsersTable';
import useAuthorities from '../../hooks/useAuthorities';
import {
  selectIsLoading,
  selectIsError,
  selectUsers,
  selectEmail,
  selectRole,
  selectValidationMessage,
  fetchUsers,
  changeEmail,
  changeCheckbox,
  clearForm,
  addUser,
  deleteUser,
  setValidationMessage,
  selectErrorMessage,
} from './usersSlice';
import { selectAuthorities } from '../Login/loginSlice';
import { parseRoleLabelToValue } from '../../utils/roles';
import PageTitle from '../../components/PageTitle';

function Users() {
  const { isVoltaAdminOrResellerAdmin } = useAuthorities();
  const [anchorEl, setAnchorEl] = useState(false);

  const isLoading = useSelector(selectIsLoading);
  const isError = useSelector(selectIsError);
  const users = useSelector(selectUsers);
  const email = useSelector(selectEmail);
  const role = useSelector(selectRole);
  const validationMessage = useSelector(selectValidationMessage);
  const errorMessage = useSelector(selectErrorMessage);
  const loggedAuthorities = useSelector(selectAuthorities);

  const dispatch = useDispatch();

  useLayoutEffect(() => {
    dispatch(fetchUsers());
    return () => dispatch(clearForm());
  }, [dispatch, fetchUsers]);

  const handleEmailChange = (e) => {
    dispatch(changeEmail(e.target.value));
  };

  const handleCheckboxChange = (event, value) => {
    dispatch(changeCheckbox({ name: event.target.name, value }));
  };

  const handleAddEmail = (e) => {
    e.preventDefault();
    const lowerEmail = email.toLowerCase();

    if (isValidated(lowerEmail) === false) {
      return;
    }

    const authorities = parseRoleLabelToValue(role, loggedAuthorities);
    dispatch(addUser(lowerEmail, authorities));
  };

  const handleDeleteEmail = (username) => {
    dispatch(deleteUser(username));
  };

  const isValidated = (email) => {
    const emailRegex =
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g;

    if (emailRegex.test(email) === false) {
      dispatch(setValidationMessage('Please enter a valid email address.'));
      return false;
    }
    if (users.find((user) => user.username === email)) {
      dispatch(setValidationMessage('This email is already in use.'));
      return false;
    }
    dispatch(setValidationMessage(''));
    return true;
  };

  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const popoverBody = (
    <PopoverWrapper>
      <Grid item>Add user</Grid>
      <Grid item>
        <form onSubmit={handleAddEmail}>
          <FormControl>
            <TextField
              id="email"
              placeholder="Email"
              value={email}
              onChange={handleEmailChange}
              fullWidth
            />
            {validationMessage && (
              <Typography color="error">{validationMessage}</Typography>
            )}

            <FormControlLabel
              control={
                <Checkbox
                  checked={role.admin}
                  onChange={handleCheckboxChange}
                  name="admin"
                  color="primary"
                />
              }
              label="Admin"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={role.nonAdmin}
                  onChange={handleCheckboxChange}
                  name="nonAdmin"
                  color="primary"
                />
              }
              label="Non-admin"
            />
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Add user
            </Button>
          </FormControl>
        </form>
      </Grid>
    </PopoverWrapper>
  );

  return (
    <>
      <PageTitle title="Users">
        {isVoltaAdminOrResellerAdmin() ? (
          <Button
            color="default"
            startIcon={<AddIcon />}
            onClick={handleOpenPopover}
            style={{ marginTop: '12px' }}
          >
            Add
          </Button>
        ) : null}
      </PageTitle>
      {isLoading && !isError && <Loader data-testid="loader" />}
      {!isLoading && isError && <ErrorText>{errorMessage}</ErrorText>}
      {!isLoading && !isError && users && (
        <UsersTable
          users={users}
          handleDeleteEmail={handleDeleteEmail}
          isVoltaAdminOrResellerAdmin={isVoltaAdminOrResellerAdmin}
        />
      )}
      <Popover
        id="popover"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {popoverBody}
      </Popover>
    </>
  );
}

export default Users;
