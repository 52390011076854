import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { BsFillQuestionDiamondFill as InfoIcon } from 'react-icons/bs';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useSpring, animated } from 'react-spring';

import LinearProgress from '../../../components/LinearProgress';
import { parseNumberToString } from '../../../utils/parseData';
import {
  getApplianceLabel,
  sortAppliancesDescending,
} from '../../../utils/appliances';

const parseDataObjectToArray = (dataWithoutNumberOfDevices) => {
  const keys = Object.keys(dataWithoutNumberOfDevices);
  const array = keys.map((key) => {
    return {
      title: getApplianceLabel(key.replace('Percentage', '')),
      percentage: dataWithoutNumberOfDevices[key].percentage,
      numberOfDevices: dataWithoutNumberOfDevices[key].numberOfDevices,
    };
  });
  const sortedArray = sortAppliancesDescending(array);
  return sortedArray;
};

function DisaggregatedTable({ data }) {
  const dataWithoutNumberOfDevices = { ...data };
  delete dataWithoutNumberOfDevices.numberOfDevices;

  const tableRows = parseDataObjectToArray(dataWithoutNumberOfDevices);

  const props = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
  });
  const AnimatedTableContainer = animated(TableContainer);

  return (
    <AnimatedTableContainer component={Paper} style={props}>
      <Table aria-label="table">
        <TableHead>
          <TableRow>
            <TableCell align="left" style={{ width: '200px' }}>
              Appliance
            </TableCell>
            <TableCell align="center">
              % Devices{' '}
              <InfoIcon
                fill="#388e3c"
                size={16}
                style={{
                  marginLeft: '10px',
                }}
                data-tip
                data-for="titleInfo"
              />
            </TableCell>
            <TableCell align="center" style={{ width: '170px' }}>
              # Devices
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableRows.map((row) => (
            <TableRow key={row.title}>
              <TableCell align="left">{row.title}</TableCell>
              <TableCell align="center">
                <LinearProgress
                  variant="determinate"
                  value={row.percentage}
                  style={{ height: '13px', borderRadius: '3px' }}
                />
              </TableCell>
              <TableCell align="center">{row.numberOfDevices}</TableCell>
            </TableRow>
          ))}
          <TableRow key="numberOfDevices">
            <TableCell align="left">Number of Devices</TableCell>
            <TableCell align="center"></TableCell>
            <TableCell align="center">
              {data && parseNumberToString(data.numberOfDevices)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <ReactTooltip id="titleInfo" place="top" type="dark" effect="solid">
        <div>
          This % represents the number of users our algorithm detected that have
          that appliance in comparison to the total number of users.
        </div>
        <div style={{ textAlign: 'center' }}>
          (users with a specific appliance detected by our algorithm) / (total
          number of users)
        </div>
      </ReactTooltip>
    </AnimatedTableContainer>
  );
}

DisaggregatedTable.propTypes = {
  data: PropTypes.object.isRequired,
};

export default DisaggregatedTable;
