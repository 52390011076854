import { createSlice } from '@reduxjs/toolkit';
import API from '../../api';
import { sortResellerList } from '../../utils/resellers';
import { addReseller } from '../../components/DashboardFilter/dashboardFilterSlice';
import { EMPTY_RESELLER_OPTION } from '../../utils/constants';

export const resellersAccessSlice = createSlice({
  name: 'resellersAccess',
  initialState: {
    isLoading: false,
    isError: false,
    resellersWithoutIntegrationList: null,
    resellerWithoutIntegration: {
      name: EMPTY_RESELLER_OPTION,
      integratorId: '',
    },
    email: '',
    validationMessage: '',
    isIntegrateLoading: false,
    isIntegrateError: false,
  },
  reducers: {
    fetchStart: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    fetchSuccess: (state, action) => {
      state.isLoading = false;
      state.resellersWithoutIntegrationList = sortResellerList(action.payload);
    },
    fetchError: (state) => {
      state.isLoading = false;
      state.isError = true;
    },
    changeSelect: (state, action) => {
      state[action.payload.name] = action.payload.value;
    },
    changeInput: (state, action) => {
      state[action.payload.name] = action.payload.value;
    },
    clearInput: (state) => {
      state.resellerWithoutIntegration = {
        name: EMPTY_RESELLER_OPTION,
        integratorId: '',
      };
      state.email = '';
      state.validationMessage = '';
      state.isIntegrateError = false;
    },
    integrateStart: (state) => {
      state.isIntegrateLoading = true;
      state.isIntegrateError = false;
    },
    integrateSuccess: (state, action) => {
      state.isIntegrateLoading = false;
      state.resellersWithoutIntegrationList = state.resellersWithoutIntegrationList.filter(
        (reseller) => reseller.email !== action.payload
      );
      state.resellerWithoutIntegration = {
        name: EMPTY_RESELLER_OPTION,
        integratorId: '',
      };
      state.email = '';
    },
    integrateError: (state) => {
      state.isIntegrateLoading = false;
      state.isIntegrateError = true;
    },
    setValidationMessage: (state, action) => {
      state.validationMessage = action.payload;
    },
  },
});

export const {
  fetchStart,
  fetchSuccess,
  fetchError,
  changeSelect,
  changeInput,
  clearInput,
  setValidationMessage,
  integrateStart,
  integrateSuccess,
  integrateError,
} = resellersAccessSlice.actions;

export const fetchResellersWithoutIntegration = () => async (dispatch) => {
  try {
    dispatch(fetchStart());
    const response = await API.RESELLERS.WITHOUT_INTEGRATION();
    dispatch(fetchSuccess(response.data));
  } catch (err) {
    dispatch(fetchError());
  }
};

export const integrateReseller = (reseller, email) => async (dispatch) => {
  try {
    dispatch(integrateStart());
    await API.RESELLERS.INTEGRATE(reseller.integratorId, email);

    dispatch(integrateSuccess(email));

    const newReseller = { ...reseller, email };
    dispatch(addReseller(newReseller));
  } catch (err) {
    dispatch(integrateError());
  }
};

export const selectIsLoading = (state) => state.resellersAccess.isLoading;
export const selectIsError = (state) => state.resellersAccess.isError;
export const selectResellersWithoutIntegrationList = (state) =>
  state.resellersAccess.resellersWithoutIntegrationList;
export const selectResellerWithoutIntegration = (state) =>
  state.resellersAccess.resellerWithoutIntegration;
export const selectEmail = (state) => state.resellersAccess.email;
export const selectValidationMessage = (state) =>
  state.resellersAccess.validationMessage;
export const selectIsIntegrateLoading = (state) =>
  state.resellersAccess.isIntegrateLoading;
export const selectIsIntegrateError = (state) =>
  state.resellersAccess.isIntegrateError;

export default resellersAccessSlice.reducer;
