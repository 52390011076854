import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Box, UpArrow, DownArrow, TooltipPercentage } from './styles';
import { parseNumberToString } from '../../utils/parseData';

const REFRESH_DELAY = 5;

function ChartTooltip({ tooltipData = null }) {
  const [xPosition, setXPosition] = useState(0);
  const [yPosition, setYPosition] = useState(0);
  const isReady = useRef(true);

  const updateMousePosition = (e) => {
    if (isReady.current === false) {
      return;
    }

    setXPosition(e.pageX + 30);
    setYPosition(e.pageY - 40 * tooltipData.length);
    isReady.current = false;

    return setTimeout(() => {
      isReady.current = true;
    }, REFRESH_DELAY);
  };

  useEffect(() => {
    document.addEventListener('mousemove', updateMousePosition);
    return () => document.removeEventListener('mousemove', updateMousePosition);
  }, []);

  return (
    <Wrapper xPosition={xPosition} yPosition={yPosition}>
      {tooltipData.map((element) => (
        <div key={element.id}>
          <div>
            <Box fill={element.color} />
            {element.title}: {element.value}
          </div>
          {(element.diffLastPeriod || element.diffLastPeriod === 0) && (
            <div>
              {element.diffLastPeriod >= 0 && (
                <UpArrow fill="#6ab42d" size={25} />
              )}
              {element.diffLastPeriod < 0 && (
                <DownArrow fill="#ff0033" size={25} />
              )}
              <TooltipPercentage isNegative={element.diffLastPeriod < 0}>
                {parseNumberToString(element.diffLastPeriod, 1)}%
              </TooltipPercentage>
            </div>
          )}
        </div>
      ))}
    </Wrapper>
  );
}

ChartTooltip.propTypes = {
  tooltipData: PropTypes.array,
};

export default ChartTooltip;
