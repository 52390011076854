import { createSlice } from '@reduxjs/toolkit';
import API from '../../api';
import { MESSAGES } from '../../utils/constants';

export const nonDisaggregatedSlice = createSlice({
  name: 'nonDisaggregated',
  initialState: {
    isLoading: false,
    isError: false,
    errorMessage: MESSAGES.SERVER_ERROR,
    data: null,
  },
  reducers: {
    fetchStart: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    fetchSuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    fetchError: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
    clearData: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.errorMessage = MESSAGES.SERVER_ERROR;
      state.data = null;
    },
  },
});

export const {
  fetchStart,
  fetchSuccess,
  fetchError,
  clearData,
} = nonDisaggregatedSlice.actions;

export const fetchData = (date, integratorId, ninetyPercentConnected, deviceType) => async (
  dispatch
) => {
  try {
    dispatch(fetchStart());

    const response = await API.NON_DISAGGREGATED(
      date,
      integratorId,
      ninetyPercentConnected,
      deviceType,
    );

    if (response.data.numberOfDevices === null) {
      return dispatch(fetchError(MESSAGES.NO_DATA));
    }

    dispatch(fetchSuccess(response.data));
  } catch (err) {
    if (err.response && err.response.status === 404) {
      return dispatch(fetchError(MESSAGES.NO_DATA));
    }

    dispatch(fetchError(MESSAGES.SERVER_ERROR));
  }
};

export const selectIsLoading = (state) => state.nonDisaggregated.isLoading;
export const selectIsError = (state) => state.nonDisaggregated.isError;
export const selectErrorMessage = (state) =>
  state.nonDisaggregated.errorMessage;
export const selectData = (state) => state.nonDisaggregated.data;

export default nonDisaggregatedSlice.reducer;
