import { createSlice } from '@reduxjs/toolkit';
import API from '../../api';
import { MESSAGES } from '../../utils/constants';

export const overviewSlice = createSlice({
  name: 'overview',
  initialState: {
    isLoading: false,
    isError: false,
    errorMessage: MESSAGES.SERVER_ERROR,
    data: null,
    displayType: 'consumption',
  },
  reducers: {
    fetchStart: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    fetchSuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    fetchError: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
    clearData: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.errorMessage = MESSAGES.SERVER_ERROR;
      state.data = null;
      state.displayType = 'consumption';
    },
  },
});

export const { fetchStart, fetchSuccess, fetchError, clearData } =
  overviewSlice.actions;

export const fetchData =
  ({
    date,
    integratorId,
    ninetyPercentConnected,
    appliances,
    propertyType,
    homeSize,
    personAmount,
    bedroomsAmount,
    homeType,
    deviceType,
  }) =>
  async (dispatch) => {
    try {
      dispatch(fetchStart());

      if (appliances && appliances.length < 1) {
        return dispatch(fetchError(MESSAGES.NO_DATA));
      }

      const response = await API.OVERVIEW({
        date,
        integratorId,
        ninetyPercentConnected,
        appliances,
        propertyType,
        homeSize,
        personAmount,
        bedroomsAmount,
        homeType,
        deviceType,
      });

      if (
        response.data.summary &&
        !response.data.summary.consumption.average &&
        !response.data.summary.cost.average
      ) {
        return dispatch(fetchError(MESSAGES.NO_DATA));
      }

      dispatch(fetchSuccess(response.data));
    } catch (err) {
      if (err.response && err.response.status === 404) {
        return dispatch(fetchError(MESSAGES.NO_DATA));
      }
      dispatch(fetchError(MESSAGES.SERVER_ERROR));
    }
  };

export const selectIsLoading = (state) => state.overview.isLoading;
export const selectIsError = (state) => state.overview.isError;
export const selectErrorMessage = (state) => state.overview.errorMessage;
export const selectData = (state) => state.overview.data;
export const selectDisplayType = (state) => state.overview.displayType;

export default overviewSlice.reducer;
