import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
import Grid from '@material-ui/core/Grid';

import { parseNumberToString } from '../../../utils/parseData';
import Card from '../../../components/Card';

const StyledGrid = styled(Grid)`
  margin-bottom: 10px;
`;
function NonDisaggregatedCards({ data }) {
  const props = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
  });
  const AnimatedGrid = animated(StyledGrid);

  return (
    <AnimatedGrid container spacing={3} style={props}>
      <Card
        xs={12}
        md={4}
        title="Average"
        data={parseNumberToString(data.average, 1) + '%'}
      />
      <Card
        xs={12}
        md={4}
        title="Median"
        data={parseNumberToString(data.median, 1) + '%'}
      />
      <Card
        xs={12}
        md={4}
        title="Number of Devices"
        data={parseNumberToString(data.numberOfDevices)}
      />
    </AnimatedGrid>
  );
}

NonDisaggregatedCards.propTypes = {
  data: PropTypes.object.isRequired,
};

export default NonDisaggregatedCards;
