import { AUTHORITIES } from './constants';

const roles = [
  {
    label: 'Admin',
    value: AUTHORITIES.VOLTA_ADMIN,
  },
  {
    label: 'Non-admin',
    value: AUTHORITIES.VOLTA_USER,
  },
  {
    label: 'Admin',
    value: AUTHORITIES.RESELLER_ADMIN,
  },
  {
    label: 'Non-admin',
    value: AUTHORITIES.RESELLER_USER,
  },
];

export const parseRoleValueToLabel = (roleValue) => {
  const role = roles.find((role) => role.value === roleValue);
  return role.label;
};

export const parseRoleLabelToValue = (role, authorities) => {
  let prefix = 'VOLTA_';
  if (
    authorities &&
    (authorities[0] === AUTHORITIES.VOLTA_ADMIN ||
      authorities[0] === AUTHORITIES.VOLTA_USER)
  ) {
    prefix = 'VOLTA_';
  } else if (
    authorities &&
    (authorities[0] === AUTHORITIES.RESELLER_ADMIN ||
      authorities[0] === AUTHORITIES.RESELLER_USER)
  ) {
    prefix = 'RESELLER_';
  }

  let activeRole = 'ADMIN';
  if (role.admin) {
    activeRole = 'ADMIN';
  } else if (role.nonAdmin) {
    activeRole = 'USER';
  }
  return prefix + activeRole;
};
