import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ReactComponent as Image } from '../../images/voltaware-logo.svg';

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const LogoLink = styled(Link)`
  display: flex;
  min-height: 64px;
  align-items: center;
  justify-content: center;
  background: #fff;
`;

export const Logo = styled(Image)`
  width: 140px;
  height: 100%;
`;

export const RetractableButton = styled.div`
  width: 258px;
  height: 50px;
  background: #348337;
  padding-left: ${({ isSidebarOpen }) => (!isSidebarOpen ? '15px' : '30px')};
  display: flex;
  align-items: center;
  color: #fff;
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  transition: padding 0.2s;

  & > svg {
    ${({ isSidebarOpen }) => !isSidebarOpen && 'transform: rotate(180deg);'}
  }
`;
