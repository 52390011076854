import { createSlice } from '@reduxjs/toolkit';
import API from '../../api';
import { MESSAGES } from '../../utils/constants';

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState: {
    isLoading: false,
    isError: false,
    errorMessage: MESSAGES.SERVER_ERROR,
    data: null,
  },
  reducers: {
    fetchStart: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    fetchSuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    fetchError: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
    clearData: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.errorMessage = MESSAGES.SERVER_ERROR;
      state.data = null;
    },
  },
});

export const { fetchStart, fetchSuccess, fetchError, clearData } =
  subscriptionSlice.actions;

export const fetchData =
  ({ fromDate, toDate, integratorId, deviceType }) =>
  async (dispatch) => {
    try {
      dispatch(fetchStart());
      const response = await API.SUBSCRIPTION({
        fromDate,
        toDate,
        integratorId,
        deviceType,
      });
      if (!response) {
        throw new Error();
      }

      if (response.data && response.data.monthStatus.length < 1) {
        return dispatch(fetchError(MESSAGES.NO_DATA));
      }

      if (isZeroValuesData(response.data)) {
        return dispatch(fetchError(MESSAGES.NO_DATA));
      }

      dispatch(fetchSuccess(response.data.monthStatus));
    } catch (err) {
      if (err.response && err.response.status === 404) {
        return dispatch(fetchError(MESSAGES.NO_DATA));
      }

      dispatch(fetchError(MESSAGES.SERVER_ERROR));
    }
  };

const isZeroValuesData = (data) => {
  return (
    data &&
    data.monthStatus.length === 1 &&
    data.monthStatus[0].connected.value === 0 &&
    data.monthStatus[0].activated.value === 0 &&
    data.monthStatus[0].droppedOut.value === 0 &&
    data.monthStatus[0].reactivated.value === 0
  );
};

export const selectIsLoading = (state) => state.subscription.isLoading;
export const selectIsError = (state) => state.subscription.isError;
export const selectErrorMessage = (state) => state.subscription.errorMessage;
export const selectData = (state) => state.subscription.data;

export default subscriptionSlice.reducer;
