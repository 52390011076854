import { useEffect, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import {
  selectIsFilterLoading,
  selectIsFilterError,
  fetchResellers,
  selectResellerList,
  selectFilter,
} from '../../components/DashboardFilter/dashboardFilterSlice';

import useAuthorities from '../../hooks/useAuthorities';
import {
  selectIsLoading,
  selectIsError,
  selectData,
  selectDisplayType,
  selectErrorMessage,
  fetchData,
  clearData,
} from './overviewSlice';
import Loader from '../../components/Loader';
import { ErrorText, Text } from '../../components/typography';
import DashboardFilter from '../../components/DashboardFilter';
import OverviewFilter from './OverviewFilter';
import {
  selectPropertyFilter,
  selectAppliancesFilter,
} from './OverviewFilter/overviewFilterSlice';
import OverviewTable from './OverviewTable';
import OverviewCards from './OverviewCards';
import { EMPTY_RESELLER_OPTION, MESSAGES } from '../../utils/constants';
import { getActiveAppliances } from '../../utils/appliances';
import PageTitle from '../../components/PageTitle';

function Overview() {
  const { isAdminOrVolta } = useAuthorities();

  const resellerList = useSelector(selectResellerList);
  const isFilterLoading = useSelector(selectIsFilterLoading);
  const isFilterError = useSelector(selectIsFilterError);

  const filter = useSelector(selectFilter);
  const isLoading = useSelector(selectIsLoading);
  const isError = useSelector(selectIsError);
  const errorMessage = useSelector(selectErrorMessage);
  const data = useSelector(selectData);
  const displayType = useSelector(selectDisplayType);
  const dispatch = useDispatch();

  const propertyFilter = useSelector(selectPropertyFilter);
  const appliancesFilter = useSelector(selectAppliancesFilter);
  
  const selectedFilters = [
    ...(filter?.ninetyPercentConnected ? ['Devices connected > 90% of time'] : []),
    ...(filter?.deviceType ? [`Device type (${filter.deviceType})`] : []),
    ...(appliancesFilter && Object.keys(appliancesFilter)?.some(appliance => appliancesFilter[appliance].isActive) ? ['Appliances selected'] : []),
    ...(propertyFilter?.propertyType?.value !== 'ALL' ? ['Property filters selected'] : []),
  ];

  useEffect(() => {
    if (!resellerList) {
      dispatch(fetchResellers());
    }
  }, [dispatch, resellerList]);

  useLayoutEffect(() => {
    dispatch(clearData());
  }, [dispatch, clearData]);

  useLayoutEffect(() => {
    if (isAdminOrVolta() && filter.reseller.name === EMPTY_RESELLER_OPTION) {
      return;
    }
    dispatch(
      fetchData({
        date: moment(filter.date).format('YYYY-MM'),
        integratorId: filter.reseller.integratorId,
        ninetyPercentConnected: filter.ninetyPercentConnected,
        appliances: getActiveAppliances(appliancesFilter),
        propertyType: propertyFilter.propertyType.value,
        homeSize: propertyFilter.homeSize,
        personAmount: propertyFilter.personAmount.value,
        bedroomsAmount: propertyFilter.bedroomsAmount.value,
        homeType: propertyFilter.homeType.value,
        deviceType: filter.deviceType,
      })
    );
  }, [dispatch, filter, propertyFilter, appliancesFilter]);

  return (
    <>
      {isFilterLoading && !isFilterError && <Loader data-testid="loader" />}

      {!isFilterLoading && isFilterError && (
        <ErrorText>{MESSAGES.SERVER_ERROR}</ErrorText>
      )}

      {!isFilterLoading && !isFilterError && resellerList && (
        <>
          <PageTitle 
            title={"User Insights Dashboard"}
            tooltip={selectedFilters.length > 0 ? "Note that the there's filters applied to the data you are seeing" : null}
            filters={selectedFilters}>
            <DashboardFilter
              showNinetyPercentFilter
              CustomFilterComponent={<OverviewFilter />}
            />
          </PageTitle>

          {isLoading && !isError && <Loader data-testid="loader" />}

          {!isLoading && isError && <ErrorText>{errorMessage}</ErrorText>}
          {isAdminOrVolta() &&
            filter.reseller &&
            filter.reseller.name === EMPTY_RESELLER_OPTION && (
              <Text>Please, select a reseller.</Text>
            )}

          {!isLoading && !isError && data && (
            <>
              <OverviewCards data={data} displayType={displayType} />
              <OverviewTable
                data={data[displayType]}
                displayType={displayType}
              />
            </>
          )}
        </>
      )}
    </>
  );
}

export default Overview;
