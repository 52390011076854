import PropTypes from 'prop-types';
import { Text } from './styles';

const calculateWidth = (value) => {
  switch (true) {
    case value < 10:
      return 20;
    case value < 100:
      return 30;
    case value < 1000:
      return 40;
    case value < 10000:
      return 50;
    default:
      return 40;
  }
};

const getBarWidth = () => {
  const barRefArray = document.getElementsByClassName('recharts-bar-rectangle');
  let highestWidth = 0;
  [...barRefArray].map((barRef) => {
    const currentBarWidth = barRef.getBBox().width;
    if (currentBarWidth > highestWidth) {
      highestWidth = currentBarWidth;
    }
    return null;
  });
  return highestWidth;
};

function BarLabel({ x = null, value = null, onMouseEnter, minValue }) {
  const barRef = document.getElementsByClassName('recharts-bar-rectangle')[0];
  if (!barRef) {
    return null;
  }

  const barWidth = getBarWidth();

  if (parseInt(value) < minValue) {
    return null;
  }

  const width = calculateWidth(value);
  return (
    <Text
      x={x - width / 2 + barWidth / 2}
      y={217}
      width={width}
      height={23}
      className="recharts-text recharts-BarLabel"
      onMouseEnter={onMouseEnter}
    >
      {value}
    </Text>
  );
}

BarLabel.propTypes = {
  x: PropTypes.number,
  value: PropTypes.number,
  onMouseEnter: PropTypes.func.isRequired,
  minValue: PropTypes.number.isRequired,
};

export default BarLabel;
