import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
import ReactTooltip from 'react-tooltip';
import { BsFillQuestionDiamondFill as InfoIcon } from 'react-icons/bs';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import LinearProgress from '../../../components/LinearProgress';
import {
  parseNumberToString,
  parseUnit,
  parseTitleCase,
} from '../../../utils/parseData';
import {
  getApplianceColor,
  getApplianceLabel,
  sortAppliancesDescending,
} from '../../../utils/appliances';

const parseDataObjectToArray = (data, displayType = 'consumption') => {
  const keys = Object.keys(data);
  const array = keys.map((key) => {
    return {
      title: getApplianceLabel(key),
      percentage: data[key].percentage,
      average: data[key].average,
      value: data[key].value,
      numberOfDevices: data[key].numberOfDevices,
      color: getApplianceColor(key),
    };
  });
  const sortedArray = sortAppliancesDescending(array);
  return sortedArray;
};

const ColoredLinearProgress = styled(LinearProgress)`
  & .MuiLinearProgress-barColorPrimary {
    background: ${(props) => props.barcolor};
  }
`;

function OverviewTable({ data, displayType = 'consumption' }) {
  const tableRows = parseDataObjectToArray(data);

  const props = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
  });
  const AnimatedTableContainer = animated(TableContainer);

  return (
    <AnimatedTableContainer component={Paper} style={props}>
      <Table aria-label="table">
        <TableHead>
          <TableRow>
            <TableCell align="left" style={{ width: '200px' }}>
              Appliance
            </TableCell>
            <TableCell align="center">
              Appliance {parseTitleCase(displayType)} %{' '}
              <InfoIcon
                fill="#388e3c"
                size={16}
                style={{ marginLeft: '10px' }}
                data-tip
                data-for="titleInfo"
              />
            </TableCell>
            <TableCell align="center" style={{ width: '255px' }}>
              {displayType === 'consumption'
                ? 'Average Consumption by Device'
                : parseTitleCase(displayType)}
            </TableCell>
            <TableCell align="center" style={{ width: '165px' }}>
              {displayType === 'consumption'
                ? 'Total Consumption'
                : parseTitleCase(displayType)}
            </TableCell>
            <TableCell align="center" style={{ width: '110px' }}>
              # Devices
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableRows.map((row) => (
            <TableRow key={row && row.title}>
              <TableCell align="left">{row && row.title}</TableCell>
              <TableCell align="center">
                <ColoredLinearProgress
                  variant="determinate"
                  value={row.percentage}
                  style={{ height: '13px', borderRadius: '3px' }}
                  barcolor={row.color}
                />
              </TableCell>
              <TableCell align="center">
                {parseUnit(row.average, displayType, data.currency)}
              </TableCell>
              <TableCell align="center">
                {parseUnit(row.value, displayType, data.currency)}
              </TableCell>
              <TableCell align="center">
                {parseNumberToString(row && row.numberOfDevices)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <ReactTooltip id="titleInfo" place="top" type="dark" effect="solid">
        <div>
          This % represents a specific appliance consumption contrasted to all
          your users, even if they don't have that appliance.
        </div>
        <div style={{ textAlign: 'center' }}>
          (sum of appliance consumption) / (total users' consumption)
        </div>
      </ReactTooltip>
    </AnimatedTableContainer>
  );
}

OverviewTable.propTypes = {
  data: PropTypes.object.isRequired,
  displayType: PropTypes.string,
};

export default OverviewTable;
