import PropTypes from 'prop-types';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';

import Navigation from './components/Navigation';
import { Wrapper, LogoLink, Logo, RetractableButton } from './styles';

function Menu({
  closeMobilebar = null,
  isSidebarOpen = null,
  handleOpenSidebar = null,
}) {
  return (
    <Wrapper>
      <LogoLink to="/overview" onClick={closeMobilebar}>
        <Logo />
      </LogoLink>
      <Navigation
        closeMobilebar={closeMobilebar}
        isSidebarOpen={isSidebarOpen}
      />
      {handleOpenSidebar && (
        <RetractableButton
          onClick={() => handleOpenSidebar(!isSidebarOpen)}
          isSidebarOpen={isSidebarOpen}
        >
          <MenuOpenIcon />
        </RetractableButton>
      )}
    </Wrapper>
  );
}

Menu.propTypes = {
  closeMobilebar: PropTypes.func,
  isSidebarOpen: PropTypes.bool,
  handleOpenSidebar: PropTypes.func,
};

export default Menu;
