import { createSlice } from '@reduxjs/toolkit';
import API from '../../api';
import { MESSAGES } from '../../utils/constants';

export const packetMessagesSlice = createSlice({
  name: 'packetMessages',
  initialState: {
    isLoading: false,
    isError: false,
    errorMessage: MESSAGES.SERVER_ERROR,
    smartMeterId: '',
    date: new Date(),
    includeElectricReadings: false,
    data: null,
  },
  reducers: {
    changeInput: (state, action) => {
      state[action.payload.name] = action.payload.value;
    },
    fetchStart: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    fetchSuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    fetchError: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
    clearData: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.errorMessage = MESSAGES.SERVER_ERROR;
      state.data = null;
    },
  },
});

export const { changeInput, fetchStart, fetchSuccess, fetchError, clearData } =
  packetMessagesSlice.actions;

export const fetchData =
  ({ smartMeterId, integratorId, date, includeElectricReadings }) =>
  async (dispatch) => {
    try {
      dispatch(fetchStart());

      const response = await API.PACKET_MESSAGES({
        smartMeterId,
        integratorId,
        date,
        includeElectricReadings,
      });

      dispatch(fetchSuccess(response.data));
    } catch (err) {
      if (err.response && err.response.status === 404) {
        return dispatch(fetchError(MESSAGES.NO_DATA));
      }

      dispatch(fetchError(MESSAGES.SERVER_ERROR));
    }
  };

export const selectIsLoading = (state) => state.packetMessages.isLoading;
export const selectIsError = (state) => state.packetMessages.isError;
export const selectErrorMessage = (state) => state.packetMessages.errorMessage;
export const selectSmartMeterId = (state) => state.packetMessages.smartMeterId;
export const selectDate = (state) => state.packetMessages.date;
export const selectIncludeElectricReadings = (state) =>
  state.packetMessages.includeElectricReadings;
export const selectData = (state) => state.packetMessages.data;

export default packetMessagesSlice.reducer;
