import styled from 'styled-components';
import MUIList from '@material-ui/core/List';
import MUIListItem from '@material-ui/core/ListItem';
import MUIListItemText from '@material-ui/core/ListItemText';
import MUIListItemIcon from '@material-ui/core/ListItemIcon';

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #388e3c;
  padding: 10px 0;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius: 3px;
  }
`;

export const List = styled(MUIList)`
  color: #fff;
  font-size: 13px;
`;

export const ListItem = styled(MUIListItem)`
  padding-left: ${({ $isSidebarOpen }) => (!$isSidebarOpen ? '15px' : '30px')};
  padding-right: 30px;
  &:hover,
  &.active {
    background: #348337;
  }
  & .MuiListItemIcon-root,
  .MuiSvgIcon-root {
    color: #fff;
    opacity: 0.7;
  }
  transition: background 0.2s, opacity 0.2s, padding 0.2s;
`;

export const ListItemIcon = styled(MUIListItemIcon)`
  min-width: 38px;
`;

export const ListItemText = styled(MUIListItemText)`
  & > span {
    font-size: 13px;
  }
`;

export const SubHeader = styled.p`
  margin: 0;
  padding: 16px 28px 4px 28px;
`;
