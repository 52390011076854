import PropTypes from 'prop-types';
import { useSpring, animated } from 'react-spring';
import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

function ResellersTable({ resellers }) {
  const props = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
  });
  const AnimatedTableContainer = animated(TableContainer);

  return (
    <AnimatedTableContainer component={Paper} style={props}>
      <Table aria-label="table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Reseller</TableCell>
            <TableCell align="left">Email</TableCell>
            <TableCell align="center">Integration created on</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {resellers.map((row) => (
            <TableRow key={row.name}>
              <TableCell align="left">{row.name}</TableCell>
              <TableCell align="left">{row.email}</TableCell>
              <TableCell align="center">
                {moment(row.createdAt).format('Do MMMM YYYY')}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </AnimatedTableContainer>
  );
}

ResellersTable.propTypes = { resellers: PropTypes.array.isRequired };

export default ResellersTable;
