import moment from 'moment';

export const parseNumberToString = (number, decimalDigits = null) => {
  if (!number) {
    return '0';
  }

  if (typeof number === 'string') {
    return number;
  }

  if (decimalDigits) {
    return Math.abs(number).toFixed(decimalDigits).replace('.', ',');
  }

  return Math.abs(number).toString().replace('.', ',');
};

export const parseUnit = (number, type = 'consumption', unit = null) => {
  const value = parseNumberToString(number, 2);
  switch (type) {
    case 'consumption':
      return `${value} kWh`;
    case 'cost':
      return `${unit || '£'} ${value}`;
    default:
      return `${value} kWh`;
  }
};

export const parseTitleCase = (string) => {
  return string[0].toUpperCase() + string.substring(1);
};

export const maxDate = () => {
  const date = new Date();

  if (date.getDate() === 1) {
    return moment(date).subtract(1, 'months')._d;
  }

  return new Date();
};

export const getDisconnectionData = (data) => {
  const disconnectionData = [
    {
      disconnection: '0% - 5%',
      percentage:
        parseNumberToString(
          data &&
            data.lessThanFivePercent &&
            data.lessThanFivePercent.percentage,
          1
        ) + '%',
      numberOfDevices: parseNumberToString(
        data &&
          data.lessThanFivePercent &&
          data.lessThanFivePercent.numberOfDevices
      ),
    },
    {
      disconnection: '6% - 10%',
      percentage:
        parseNumberToString(
          data && data.lessThanTenPercent && data.lessThanTenPercent.percentage,
          1
        ) + '%',
      numberOfDevices: parseNumberToString(
        data &&
          data.lessThanTenPercent &&
          data.lessThanTenPercent.numberOfDevices
      ),
    },
    {
      disconnection: '11% - 15%',
      percentage:
        parseNumberToString(
          data &&
            data.lessThanFifteenPercent &&
            data.lessThanFifteenPercent.percentage,
          1
        ) + '%',
      numberOfDevices: parseNumberToString(
        data &&
          data.lessThanFifteenPercent &&
          data.lessThanFifteenPercent.numberOfDevices
      ),
    },
    {
      disconnection: '16% - 20%',
      percentage:
        parseNumberToString(
          data &&
            data.lessThanTwentyPercent &&
            data.lessThanTwentyPercent.percentage,
          1
        ) + '%',
      numberOfDevices: parseNumberToString(
        data &&
          data.lessThanTwentyPercent &&
          data.lessThanTwentyPercent.numberOfDevices
      ),
    },
    {
      disconnection: '21% - 25%',
      percentage:
        parseNumberToString(
          data &&
            data.lessThanTwentyFivePercent &&
            data.lessThanTwentyFivePercent.percentage,
          1
        ) + '%',
      numberOfDevices: parseNumberToString(
        data &&
          data.lessThanTwentyFivePercent &&
          data.lessThanTwentyFivePercent.numberOfDevices
      ),
    },
    {
      disconnection: '26% - 50%',
      percentage:
        parseNumberToString(
          data &&
            data.lessThanFiftyPercent &&
            data.lessThanFiftyPercent.percentage,
          1
        ) + '%',
      numberOfDevices: parseNumberToString(
        data &&
          data.lessThanFiftyPercent &&
          data.lessThanFiftyPercent.numberOfDevices
      ),
    },
    {
      disconnection: '51% - 75%',
      percentage:
        parseNumberToString(
          data &&
            data.lessThanSeventyFivePercent &&
            data.lessThanSeventyFivePercent.percentage,
          1
        ) + '%',
      numberOfDevices: parseNumberToString(
        data &&
          data.lessThanSeventyFivePercent &&
          data.lessThanSeventyFivePercent.numberOfDevices
      ),
    },
    {
      disconnection: '76% - 100%',
      percentage:
        parseNumberToString(
          data &&
            data.moreThanSeventyFivePercent &&
            data.moreThanSeventyFivePercent.percentage,
          1
        ) + '%',
      numberOfDevices: parseNumberToString(
        data &&
          data.moreThanSeventyFivePercent &&
          data.moreThanSeventyFivePercent.numberOfDevices
      ),
    },
  ];
  return disconnectionData;
};

export const getOthersData = (data) => {
  const othersData = [
    {
      disconnection: '0% - 10%',
      percentage:
        parseNumberToString(
          data && data.lessThanTenPercent && data.lessThanTenPercent.percentage,
          1
        ) + '%',
      numberOfDevices: parseNumberToString(
        data &&
          data.lessThanTenPercent &&
          data.lessThanTenPercent.numberOfDevices
      ),
    },
    {
      disconnection: '11% - 20%',
      percentage:
        parseNumberToString(
          data &&
            data.lessThanTwentyPercent &&
            data.lessThanTwentyPercent.percentage,
          1
        ) + '%',
      numberOfDevices: parseNumberToString(
        data &&
          data.lessThanTwentyPercent &&
          data.lessThanTwentyPercent.numberOfDevices
      ),
    },
    {
      disconnection: '21% - 30%',
      percentage:
        parseNumberToString(
          data &&
            data.lessThanThirtyPercent &&
            data.lessThanThirtyPercent.percentage,
          1
        ) + '%',
      numberOfDevices: parseNumberToString(
        data &&
          data.lessThanThirtyPercent &&
          data.lessThanThirtyPercent.numberOfDevices
      ),
    },
    {
      disconnection: '31% - 40%',
      percentage:
        parseNumberToString(
          data &&
            data.lessThanFortyPercent &&
            data.lessThanFortyPercent.percentage,
          1
        ) + '%',
      numberOfDevices: parseNumberToString(
        data &&
          data.lessThanFortyPercent &&
          data.lessThanFortyPercent.numberOfDevices
      ),
    },
    {
      disconnection: '41% - 50%',
      percentage:
        parseNumberToString(
          data &&
            data.lessThanFiftyPercent &&
            data.lessThanFiftyPercent.percentage,
          1
        ) + '%',
      numberOfDevices: parseNumberToString(
        data &&
          data.lessThanFiftyPercent &&
          data.lessThanFiftyPercent.numberOfDevices
      ),
    },
    {
      disconnection: '51% - 100%',
      percentage:
        parseNumberToString(
          data &&
            data.moreThanFiftyPercent &&
            data.moreThanFiftyPercent.percentage,
          1
        ) + '%',
      numberOfDevices: parseNumberToString(
        data &&
          data.moreThanFiftyPercent &&
          data.moreThanFiftyPercent.numberOfDevices
      ),
    },
  ];
  return othersData;
};
