import { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import MenuItem from '@material-ui/core/MenuItem';
import SettingsIcon from '@material-ui/icons/Settings';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import MenuIcon from '@material-ui/icons/Menu';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import useAuthorities from '../../hooks/useAuthorities';
import { selectReseller, logout } from '../../pages/Login/loginSlice';
import { AppBar, Menu, ResellerName, MenuButton } from './styles';

function Header({ openMobilebar }) {
  const dispatch = useDispatch();
  const reseller = useSelector(selectReseller);
  const { isAdmin } = useAuthorities();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up('sm'));

  const [settingsAnchorEl, setSettingsAnchorEl] = useState(null);
  const [powerAnchorEl, setPowerAnchorEl] = useState(null);

  const isSettingsMenuOpen = Boolean(settingsAnchorEl);
  const isPowerMenuOpen = Boolean(powerAnchorEl);

  const handleSettingsMenuOpen = (event) =>
    setSettingsAnchorEl(event.currentTarget);
  const handleSettingsMenuClose = () => setSettingsAnchorEl(null);
  const handlePowerMenuOpen = (event) => setPowerAnchorEl(event.currentTarget);
  const handlePowerMenuClose = () => {
    setPowerAnchorEl(null);
  };
  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <AppBar position="sticky">
      <Toolbar>
        {!sm && (
          <MenuButton aria-label="menu" onClick={openMobilebar}>
            <MenuIcon />
          </MenuButton>
        )}
        {reseller && (
          <ResellerName variant="h6">
            <div>{reseller.name}</div>
          </ResellerName>
        )}
        <IconButton
          aria-label="settings menu"
          aria-controls="settings-menu"
          aria-haspopup="true"
          onClick={handleSettingsMenuOpen}
        >
          <SettingsIcon />
        </IconButton>
        <IconButton
          aria-label="power menu"
          aria-controls="power-menu"
          aria-haspopup="true"
          onClick={handlePowerMenuOpen}
        >
          <PowerSettingsNewIcon />
        </IconButton>
      </Toolbar>
      <Menu
        anchorEl={settingsAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id="settings-menu"
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isSettingsMenuOpen}
        onClose={handleSettingsMenuClose}
      >
        <MenuItem
          onClick={handleSettingsMenuClose}
          component={Link}
          to="/users"
        >
          Users
        </MenuItem>
        {isAdmin() && (
          <MenuItem
            onClick={handleSettingsMenuClose}
            component={Link}
            to="/resellers-access"
          >
            Resellers Access
          </MenuItem>
        )}
      </Menu>
      <Menu
        anchorEl={powerAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id="power-menu"
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isPowerMenuOpen}
        onClose={handlePowerMenuClose}
      >
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </AppBar>
  );
}

Header.propTypes = {
  openMobilebar: PropTypes.func.isRequired,
};

export default Header;
