import styled from 'styled-components';
import { GoArrowUp, GoArrowDown } from 'react-icons/go';

export const Wrapper = styled.div.attrs(({ xPosition, yPosition }) => ({
  style: {
    left: xPosition + 'px',
    top: yPosition + 'px',
  },
}))`
  position: absolute;
  z-index: 10;
  border: 1px solid #c9c9c9;
  background: #fff;
  padding: 5px;
  padding-bottom: 0%;
  visibility: ${(props) => !props.xPosition && 'hidden'};

  display: flex;
  flex-direction: column;

  & > div {
    margin-bottom: 5px;

    > div {
      display: flex;
    }
  }
`;

export const Box = styled.div`
  width: 15px;
  height: 10px;
  margin-right: 5px;
  background: ${(props) => props.fill};
  align-self: center;
`;

export const UpArrow = styled(GoArrowUp)`
  margin-left: -5px;
`;

export const DownArrow = styled(GoArrowDown)`
  margin-left: -5px;
`;

export const TooltipPercentage = styled.span`
  display: block;
  margin-top: 5px;
  font-size: 0.9em;
  color: ${(props) => (props.isNegative ? '#ff0033' : '#6ab42d')};
`;
