import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
import Grid from '@material-ui/core/Grid';

import Card from '../../../components/Card';
import { parseUnit } from '../../../utils/parseData';

const StyledGrid = styled(Grid)`
  margin-bottom: 10px;
`;

function OverviewCards({ data, displayType }) {
  const props = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
  });
  const AnimatedGrid = animated(StyledGrid);

  return (
    <AnimatedGrid container spacing={3} style={props}>
      <Card
        xs={12}
        sm={4}
        title={`Average ${displayType} (monthly)`}
        data={parseUnit(
          data.summary[displayType].average,
          displayType,
          data.currency
        )}
      />
      <Card
        xs={12}
        sm={4}
        title={`Highest ${displayType} (monthly)`}
        data={parseUnit(
          data.summary[displayType].highest,
          displayType,
          data.currency
        )}
      />
      <Card
        xs={12}
        sm={4}
        title={`Lowest ${displayType} (monthly)`}
        data={parseUnit(
          data.summary[displayType].lowest,
          displayType,
          data.currency
        )}
      />
      <Card
        xs={12}
        sm={6}
        title={`Average weekend ${displayType} (daily)`}
        data={parseUnit(
          data.summary[displayType].weekendAverage,
          displayType,
          data.currency
        )}
      />
      <Card
        xs={12}
        sm={6}
        title={`Average weekday ${displayType} (daily)`}
        data={parseUnit(
          data.summary[displayType].weekdayAverage,
          displayType,
          data.currency
        )}
      />
    </AnimatedGrid>
  );
}

OverviewCards.propTypes = {
  data: PropTypes.object.isRequired,
  displayType: PropTypes.string.isRequired,
};

export default OverviewCards;
