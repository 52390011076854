import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import MUIFormControl from '@material-ui/core/FormControl';

export const PopoverWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
  padding: 10px;
  overflow-y: hidden;
`;

export const FormControl = styled(MUIFormControl)`
  min-width: 280px;
`;

export const ButtonGroup = styled(Grid)`
  display: flex;
  justify-content: flex-end;

  & > button:first-child {
    margin-right: 10px;
  }
`;
