import styled from 'styled-components';
import MUIDivider from '@material-ui/core/Divider';

export const PageTitle = styled.h1`
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 1.5em;
`;

export const Divider = styled(MUIDivider)`
  margin-bottom: 25px;
`;

export const Title = styled.h2`
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
  color: var(--green);
  font-weight: 700;
  font-size: 1.5em;
  font-family: Quicksand;
`;

export const Text = styled.p`
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
`;

export const ErrorText = styled(Text)`
  color: var(--error);
  font-family: Quicksand;
`;

export const SuccessText = styled(Text)`
  font-family: Quicksand;
`;
