import { useSelector, useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ListItemText from '@material-ui/core/ListItemText';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';

import {
  selectPropertyFilter,
  selectAppliancesFilter,
  changeFilter,
  changeSliderFilter,
  clearFilter,
  changeAppliancesFilter,
} from './overviewFilterSlice';
import { changeFilter as changeMainFilter } from '../../../components/DashboardFilter/dashboardFilterSlice';
import { SliderLabel, ButtonGroup } from './styles';
import Slider from '../../../components/Slider';
import {
  propertyTypeOptions,
  homeSizeMarks,
  personAmountOptions,
  bedroomsAmountOptions,
  homeTypeOptions,
} from '../../../utils/properties';

const ClearButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(red[400]),
    backgroundColor: red[400],
    '&:hover': {
      backgroundColor: red[800],
    },
  },
}))(Button);

function OverviewFilter() {
  const propertyFilter = useSelector(selectPropertyFilter);
  const appliancesFilter = useSelector(selectAppliancesFilter);
  const dispatch = useDispatch();

  const handleClearFilter = () => {
    dispatch(changeMainFilter({ name: 'deviceType', value: 'VOLTAWARE_SENSOR' }));
    dispatch(clearFilter());
  }

  const handleChange = (event, name) => {
    dispatch(changeFilter({ name, option: event.target }));
  };

  const handleSliderChange = (name, range) => {
    dispatch(changeSliderFilter({ name, min: range[0], max: range[1] }));
  };

  const handleChangeAppliancesFilter = (_, value) => {
    const currentValue = value.props.value;
    let applianceName, applianceIsActive;

    for (const name in appliancesFilter) {
      if (appliancesFilter[name].value === currentValue) {
        applianceName = name;
        applianceIsActive = appliancesFilter[name].isActive;
        break;
      }
    }

    dispatch(changeAppliancesFilter({ name: applianceName, value: !applianceIsActive }));
  };

  const applianceOptions = Object.values(appliancesFilter);
  const selectedAppliances = applianceOptions.filter(appliance => appliance.isActive);

  return (
    <Grid item container display="flex" direction="column" spacing={1}>
      <Grid item>
        <InputLabel id="appliances" shrink={true}>Appliances</InputLabel>
        <Select
          labelId="appliances"
          id="appliances"
          multiple
          name="appliances"
          value={selectedAppliances}
          renderValue={(selected) => 
            appliancesFilter?.all.isActive === true
            ? 'All appliances'
            : selected.map(appliance => appliance.label).join(', ')
          }
          onChange={handleChangeAppliancesFilter}
          style={{ width: '100%', maxWidth: 310 }}
        >
          {applianceOptions.map((option) => (
            <MenuItem
              key={option.name}
              value={option.value}>
              <Checkbox
                checked={appliancesFilter[option.name].isActive}
                disabled={appliancesFilter?.all.isActive === true && option.name !== 'all'}
              />
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item>
        <FormControl fullWidth>
          <InputLabel id="propertyType">Property type</InputLabel>
          <Select
            labelId="propertyType"
            id="propertyType"
            name="propertyType"
            value={propertyFilter.propertyType.value}
            onChange={(event) => handleChange(event, 'propertyType')}
            style={{ width: '100%' }}
          >
            {propertyTypeOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {propertyFilter.propertyType.value === 'HOME' && (
        <>
          <Grid item container spacing={1}>
            <Grid item xs={6}>
              <Slider
                name="homeSize"
                value={[...propertyFilter.homeSize]}
                handleChange={handleSliderChange}
                marks={homeSizeMarks}
                min={0}
                max={500}
                step={10}
                label={
                  <SliderLabel>
                    House size (m<sup>2</sup>)
                  </SliderLabel>
                }
              />
            </Grid>
            <Grid item xs={6} style={{ marginTop: '5px' }}>
              <FormControl fullWidth>
                <InputLabel id="personAmount">Person amount</InputLabel>
                <Select
                  labelId="personAmount"
                  id="personAmount"
                  name="personAmount"
                  value={propertyFilter.personAmount.value}
                  onChange={(event) => handleChange(event, 'personAmount')}
                >
                  {personAmountOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item container spacing={1}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="bedroomsAmount">Bedrooms amount</InputLabel>
                <Select
                  labelId="bedroomsAmount"
                  id="bedroomsAmount"
                  name="bedroomsAmount"
                  value={propertyFilter.bedroomsAmount.value}
                  onChange={(event) => handleChange(event, 'bedroomsAmount')}
                >
                  {bedroomsAmountOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="homeType">Home type</InputLabel>
                <Select
                  labelId="homeType"
                  id="homeType"
                  name="homeType"
                  value={propertyFilter.homeType.value}
                  onChange={(event) => handleChange(event, 'homeType')}
                >
                  {homeTypeOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </>
      )}
      <ButtonGroup item>
        <ClearButton
          variant="contained"
          onClick={handleClearFilter}
        >
          Clear filters
        </ClearButton>
      </ButtonGroup>
    </Grid>
  );
}

export default OverviewFilter;
