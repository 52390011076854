import { useState } from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Label, Input } from './styles';
import MaterialSlider from '@material-ui/core/Slider';

function Slider({
  name,
  value,
  handleChange,
  marks,
  min,
  max,
  step,
  label = null,
}) {
  const [sliderValue, setSliderValue] = useState(value);

  const handleSliderChange = (e, newValue) => {
    setSliderValue(newValue);
  };

  const handleMouseUp = (e, newValue) => {
    handleChange(name, newValue);
  };

  const valueLabelFormat = (value) => {
    if (value === min) {
      return '';
    }
    if (value > max) {
      return '';
    }
    return value;
  };

  return (
    <Wrapper>
      {label && <Label>{label}</Label>}
      <Input data-testid={name}>
        <MaterialSlider
          value={sliderValue}
          onChange={handleSliderChange}
          onChangeCommitted={handleMouseUp}
          valueLabelDisplay="on"
          step={step}
          min={min}
          max={max + step}
          marks={marks}
          valueLabelFormat={valueLabelFormat}
        />
      </Input>
    </Wrapper>
  );
}

Slider.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  marks: PropTypes.array.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default Slider;
