import { createSlice } from '@reduxjs/toolkit';
import API from '../../api';
import { MESSAGES } from '../../utils/constants';

const errorMessages = {
  '1': 'An error occurred while trying to upload the file: invalid ending date.',
  '2': 'An error occurred while trying to upload the file: date interval exceeds 30 days.',
  '3': 'An error occurred while trying to upload the file: missing file header.',
  '4': MESSAGES.FILE_UPLOAD_ERROR,
  '999': MESSAGES.FILE_UPLOAD_ERROR, // Generic
};

export const costConsumptionUploadSlice = createSlice({
  name: 'costConsumptionUpload',
  initialState: {
    isLoading: false,
    isError: false,
    successMessage: '',
    errorMessage: MESSAGES.SERVER_ERROR,
  },
  reducers: {
    uploadStart: (state) => {
      state.isLoading = true;
      state.isError = false;
      state.successMessage = '';
    },
    uploadSuccess: (state) => {
      state.isLoading = false;
      state.successMessage = MESSAGES.FILE_UPLOADED;
    },
    uploadError: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
    clearData: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.errorMessage = MESSAGES.SERVER_ERROR;
      state.successMessage = '';
    },
  },
});

export const { uploadStart, uploadSuccess, uploadError, clearData } =
  costConsumptionUploadSlice.actions;

export const uploadFile =
  (data) =>
  async (dispatch) => {
    try {
      dispatch(uploadStart());
      const response = await API.COST_CONSUMPTION.FILE_UPLOAD(data);
      if (!response) {
        throw new Error();
      }

      dispatch(uploadSuccess());
    } catch (err) {
      const errorCode = err.response?.data?.errorCode;
      const message = errorMessages?.[errorCode] ?? errorMessages['999'];

      dispatch(uploadError(message));
    }
  };

export const selectIsLoading = (state) => state.costConsumptionUpload.isLoading;
export const selectIsError = (state) => state.costConsumptionUpload.isError;
export const selectErrorMessage = (state) => state.costConsumptionUpload.errorMessage;
export const selectSuccessMessage = (state) => state.costConsumptionUpload.successMessage;

export default costConsumptionUploadSlice.reducer;
