import { createSlice } from '@reduxjs/toolkit';
import API from '../../api';
import { MESSAGES } from '../../utils/constants';

export const reportedSlice = createSlice({
  name: 'reported',
  initialState: {
    isLoading: false,
    isError: false,
    errorMessage: MESSAGES.SERVER_ERROR,
    data: null,
  },
  reducers: {
    fetchStart: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    fetchSuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    fetchError: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
    clearData: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.errorMessage = MESSAGES.SERVER_ERROR;
      state.data = null;
    },
  },
});

export const {
  fetchStart,
  fetchSuccess,
  fetchError,
  clearData,
} = reportedSlice.actions;

export const fetchData = (date, integratorId) => async (dispatch) => {
  try {
    dispatch(fetchStart());

    const response = await API.REPORTED(date, integratorId);

    if (!response) {
      throw new Error();
    }

    if (!response.data.numberOfDevices) {
      return dispatch(fetchError(MESSAGES.NO_DATA));
    }

    dispatch(fetchSuccess(response.data));
  } catch (err) {
    if (err.response && err.response.status === 404) {
      return dispatch(fetchError(MESSAGES.NO_DATA));
    }

    dispatch(fetchError(MESSAGES.SERVER_ERROR));
  }
};

export const selectIsLoading = (state) => state.reported.isLoading;
export const selectIsError = (state) => state.reported.isError;
export const selectErrorMessage = (state) => state.reported.errorMessage;
export const selectData = (state) => state.reported.data;

export default reportedSlice.reducer;
