import { createSlice } from '@reduxjs/toolkit';
import API from '../../api';
import { maxDate } from '../../utils/parseData';

import { sortResellerList } from '../../utils/resellers';
import { EMPTY_RESELLER_OPTION } from '../../utils/constants';

export const dashboardFilterSlice = createSlice({
  name: 'dashboardFilter',
  initialState: {
    filter: {
      date: maxDate(),
      reseller: {
        name: EMPTY_RESELLER_OPTION,
        integratorId: '',
        createdAt: null,
      },
      ninetyPercentConnected: true,
      deviceType: 'VOLTAWARE_SENSOR',
    },
    isFilterLoading: false,
    isFilterError: false,
    resellerList: null,
    filteredResellerList: null,
  },
  reducers: {
    changeFilter: (state, action) => {
      state.filter[action.payload.name] = action.payload.value;

      if (action.payload.name === 'reseller') {
        state.filter.date = maxDate();
      }
    },
    clearFilter: (state) => {
      state.filter = {
        date: maxDate(),
        reseller: {
          name: EMPTY_RESELLER_OPTION,
          integratorId: '',
          createdAt: null,
        },
        ninetyPercentConnected: true,
      };
      state.isFilterError = false;
      state.resellerList = null;
    },
    fetchStart: (state) => {
      state.isFilterLoading = true;
      state.isFilterError = false;
    },
    fetchSuccess: (state, action) => {
      state.isFilterLoading = false;
      state.resellerList = sortResellerList(action.payload);
      state.filteredResellerList = sortResellerList(action.payload);
    },
    fetchError: (state) => {
      state.isFilterLoading = false;
      state.isFilterError = true;
    },
    addReseller: (state, action) => {
      state.resellerList.unshift(action.payload);
      state.filteredResellerList.unshift(action.payload);
    },
    changeFilteredResellerList: (state, action) => {
      state.filteredResellerList = state.resellerList.filter((reseller) =>
        reseller.name.toLowerCase().includes(action.payload.toLowerCase())
      );
    },
  },
});

export const {
  changeFilter,
  clearFilter,
  fetchStart,
  fetchSuccess,
  fetchError,
  addReseller,
  changeFilteredResellerList,
} = dashboardFilterSlice.actions;

export const fetchResellers = () => async (dispatch) => {
  try {
    dispatch(fetchStart());

    const response = await API.RESELLERS.WITH_INTEGRATION();
    if (!response) {
      throw new Error();
    }
    dispatch(fetchSuccess(response.data));
  } catch (err) {
    dispatch(fetchError());
  }
};

export const selectFilter = (state) => state.dashboardFilter.filter;
export const selectIsFilterLoading = (state) =>
  state.dashboardFilter.isFilterLoading;
export const selectIsFilterError = (state) =>
  state.dashboardFilter.isFilterError;
export const selectResellerList = (state) => state.dashboardFilter.resellerList;
export const selectFilteredResellerList = (state) =>
  state.dashboardFilter.filteredResellerList;

export default dashboardFilterSlice.reducer;
