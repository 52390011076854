import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

export const subscriptionFilterSlice = createSlice({
  name: 'subscriptionFilter',
  initialState: {
    fromDate: moment().subtract(4, 'months').toDate(),
    toDate: new Date(),
    showFilter: {
      connected: true,
      droppedOut: true,
    },
  },
  reducers: {
    changeFromDate: (state, action) => {
      state.fromDate = action.payload.value;
    },
    changeToDate: (state, action) => {
      state.toDate = action.payload.value;
    },
    changeShowFilter: (state, action) => {
      const keys = Object.keys(state.showFilter);
      const otherKeys = keys.filter((key) => key !== action.payload.name);

      if (
        action.payload.value === false &&
        otherKeys.every((key) => state.showFilter[key] === false)
      ) {
        return;
      }

      state.showFilter[action.payload.name] = action.payload.value;
    },
  },
});

export const { changeFromDate, changeToDate, changeShowFilter } =
  subscriptionFilterSlice.actions;

export const selectFromDate = (state) => state.subscriptionFilter.fromDate;
export const selectToDate = (state) => state.subscriptionFilter.toDate;
export const selectShowFilter = (state) => state.subscriptionFilter.showFilter;

export default subscriptionFilterSlice.reducer;
