import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

export const SliderLabel = styled.div`
  opacity: 0.6;
  font-size: 12px;
`;

export const PopoverWrapper = styled(Grid)`
  width: 970px;
  padding: 10px;
`;

export const ButtonGroup = styled(Grid)`
  display: flex;
  justify-content: flex-end;
`;
