import styled from 'styled-components';
import { animated } from 'react-spring';
import TableContainer from '@material-ui/core/TableContainer';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';

const AnimatedTableContainer = animated(TableContainer);

export const Wrapper = styled(AnimatedTableContainer).attrs({component: Paper})`
  overflow-x: auto;
  width: calc(100vw - 354px);
  width: ${({ $isSidebarOpen }) =>
    $isSidebarOpen ? 'calc(100vw - 354px)' : 'calc(100vw - 152px)'};
  height: calc(100vh - 150px);

  transition: width 0.2s;

  @media (max-width: 600px) {
    width: calc(100vw - 40px);
  }

  & th {
    background-color: #fff;
    min-width: 200px;
  }
`;

export const ArrowCell = styled(TableCell)`
  min-width: auto !important;
`;
