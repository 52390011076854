import React from 'react';
import PropTypes from 'prop-types';
import MUILinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

function LinearProgress(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <MUILinearProgress
          variant="determinate"
          value={props.value}
          {...props}
        />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgress.propTypes = {
  value: PropTypes.number.isRequired,
};

export default LinearProgress;
