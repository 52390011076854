import { createSlice } from '@reduxjs/toolkit';
import API from '../../api';
import { MESSAGES } from '../../utils/constants';

export const costConsumptionResultsSlice = createSlice({
  name: 'costConsumptionResults',
  initialState: {
    isLoading: false,
    isFileDownloading: false,
    isError: false,
    errorMessage: MESSAGES.SERVER_ERROR,
    fileSuccessMessage: '',
    fileErrorMessage: '',
    data: null,
    downloadFile: null,
    downloadFileName: '',
  },
  reducers: {
    fetchStart: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    fetchSuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    fetchError: (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
      state.isError = true;
    },
    fetchFileStart: (state, action) => {
      state.isFileDownloading = true;
      state.downloadFile = null;
      state.downloadFileName = action.payload;
      state.fileErrorMessage = '';
      state.fileSuccessMessage = '';
    },
    fetchFileSuccess: (state, action) => {
      state.isFileDownloading = false;
      state.fileSuccessMessage = MESSAGES.FILE_DOWNLOADED;
      state.downloadFile = action.payload;
    },
    fetchFileError: (state, action) => {
      state.isFileDownloading = false;
      state.fileErrorMessage = action.payload;
    },
    clearData: (state) => {
      state.isLoading = false;
      state.isFileDownloading = false;
      state.isError = false;
      state.errorMessage = MESSAGES.SERVER_ERROR;
      state.fileSuccessMessage = '';
      state.data = null;
      state.downloadFile = null;
    },
  },
});

export const {
  fetchStart,
  fetchSuccess,
  fetchError,
  fetchFileStart,
  fetchFileError,
  fetchFileSuccess,
  clearData,
} = costConsumptionResultsSlice.actions;

export const fetchData = (integratorId) => async (dispatch) => {
  try {
    dispatch(fetchStart());

    const response = await API.COST_CONSUMPTION.SUMMARY(integratorId);

    if (!response.data?.uploads?.length) {
      return dispatch(fetchError(MESSAGES.NO_DATA_AVAILABLE));
    }

    dispatch(fetchSuccess(response.data));
  } catch (err) {
    if (err.response && err.response.status === 404) {
      return dispatch(fetchError(MESSAGES.NO_DATA));
    }
    dispatch(fetchError(MESSAGES.SERVER_ERROR));
  }
};

export const fetchFile = (integratorId, fileName) => async (dispatch) => {
  try {
    dispatch(fetchFileStart(fileName));

    const response = await API.COST_CONSUMPTION.FILE({ integratorId, fileName });

    dispatch(fetchFileSuccess(response.data));
  } catch (err) {
    if (err.response && err.response.status === 404) {
      return dispatch(fetchFileError(MESSAGES.FILE_DOWNLOAD_ERROR));
    }
    dispatch(fetchFileError(MESSAGES.SERVER_ERROR));
  }
}

export const selectIsLoading = (state) => state.costConsumptionResults.isLoading;
export const selectIsError = (state) => state.costConsumptionResults.isError;
export const selectErrorMessage = (state) => state.costConsumptionResults.errorMessage;
export const selectFileSuccessMessage = (state) => state.costConsumptionResults.fileSuccessMessage;
export const selectFileErrorMessage = (state) => state.costConsumptionResults.fileErrorMessage;
export const selectData = (state) => state.costConsumptionResults.data;
export const selectDownloadFile = (state) => state.costConsumptionResults.downloadFile;
export const selectDownloadFileName = (state) => state.costConsumptionResults.downloadFileName;
export const selectIsFileDownloading = (state) => state.costConsumptionResults.isFileDownloading;

export default costConsumptionResultsSlice.reducer;
