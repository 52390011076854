const DISAGGREGATED_APPLIANCES = {
  cooking: { label: 'Cooking Appliances', color: '#816bb5', value: 'COOKING' },
  heating: { label: 'Heating', color: '#816bb5', value: 'HEATING' },
  heatingCooking: {
    label: 'Heating & Cooking Appliances',
    color: '#816bb5',
    value: 'HEATING_COOKING',
  },
  airConditioner: {
    label: 'Air Conditioner',
    color: '#4f91a5',
    value: 'AIR_CONDITIONER',
  },
  fridgeFreezer: {
    label: 'Fridge & Freezer',
    color: '#4271bd',
    value: 'FRIDGE_FREEZER',
  },
  dishwasher: { label: 'Dishwasher', color: '#aaf2ee', value: 'DISHWASHER' },
  washing: { label: 'Washing Appliances', color: '#1c9991', value: 'WASHING' },
  washingMachine: {
    label: 'Washing Machine',
    color: '#1c9991',
    value: 'WASHING_MACHINE',
  },
  kettle: {
    label: 'Kettle',
    color: '#e8abce',
    value: 'KETTLE',
  },
  tumbleDryer: {
    label: 'Tumble Dryer',
    color: '#a694d4',
    value: 'TUMBLE_DRYER',
  },
  boiler: { label: 'Boiler', color: '#136b66', value: 'BOILER' },
  electricShower: {
    label: 'Electric Shower',
    color: '#52ccc5',
    value: 'ELECTRIC_SHOWER',
  },
  electricVehicle: {
    label: 'Electric Vehicle',
    color: '#731552',
    value: 'ELECTRIC_VEHICLE',
  },
  standby: { label: 'Standby', color: '#57add9', value: 'STANDBY' },
  lightingEntertainment: {
    label: 'Lighting & Entertainment Appliances',
    color: '#ffcc00',
    value: 'LIGHTING_ENTERTAINMENT',
  },
  others: {
    label: 'Others',
    color: '#999',
    value: 'OTHERS',
  },
};

export const getApplianceLabel = (key) => {
  if (DISAGGREGATED_APPLIANCES[key]) {
    return DISAGGREGATED_APPLIANCES[key].label;
  }
  return formatKeyToTitle(key);
};

export const getApplianceColor = (key) => {
  if (DISAGGREGATED_APPLIANCES[key]) {
    return DISAGGREGATED_APPLIANCES[key].color;
  }
  return '#999';
};

export const formatKeyToTitle = (key) => {
  const stringWithout = key.replace('', '');
  const stringWithSpaces = stringWithout.replace(/([A-Z])/g, ' $1');
  const title =
    stringWithSpaces.charAt(0).toUpperCase() + stringWithSpaces.slice(1);

  return title;
};

export const sortAppliancesDescending = (appliances) => {
  const sortedAppliances = [...appliances];
  sortedAppliances.sort((a, b) => b.percentage - a.percentage);

  const others = sortedAppliances.find(
    (appliance) => appliance.title === 'Others'
  );
  const othersIndex = sortedAppliances.findIndex(
    (appliance) => appliance.title === 'Others'
  );
  if (othersIndex > -1) {
    sortedAppliances.splice(othersIndex, 1);
    sortedAppliances.push(others);
  }

  return sortedAppliances;
};

export const getAllAppliancesAndSetActiveTo = (isActive) => {
  const appliances = { ...DISAGGREGATED_APPLIANCES };
  Object.keys(DISAGGREGATED_APPLIANCES).map(
    (key) =>
      (appliances[key] = {
        name: key,
        label: getApplianceLabel(key),
        value: DISAGGREGATED_APPLIANCES[key].value,
        isActive: isActive,
      })
  );
  delete appliances.others;
  return {
    all: {
      name: 'all',
      label: 'All Appliances',
      value: '',
      isActive: isActive,
    },
    ...appliances,
  };
};

export const getActiveAppliances = (appliances) => {
  if (appliances.all.isActive === true) {
    return undefined;
  }

  const activeAppliancesKeys = Object.keys(appliances).filter(
    (key) => appliances[key].isActive === true && appliances[key].name !== 'all'
  );

  const appliancesArray = activeAppliancesKeys.map(
    (applianceKey) => appliances[applianceKey].value
  );

  return appliancesArray;
};
