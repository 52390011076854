import { useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import {
  selectUserName,
  selectPassword,
  selectIsLoading,
  selectIsLoginError,
  selectErrors,
  selectIsAuth,
  changeInput,
  toogleInput,
  login,
} from './loginSlice';
import { Wrapper, LoginForm, Title, Logo } from './styles';
import logo from '../../images/login-logo.png';

function Login() {
  const username = useSelector(selectUserName);
  const password = useSelector(selectPassword);
  const isLoading = useSelector(selectIsLoading);
  const isLoginError = useSelector(selectIsLoginError);
  const isAuth = useSelector(selectIsAuth);
  const errors = useSelector(selectErrors);
  const dispatch = useDispatch();

  const history = useHistory();
  useLayoutEffect(() => {
    if (isAuth) {
      history.push('/overview');
    }
  }, [history, isAuth]);

  const handleChange = (e) => {
    dispatch(
      changeInput({ name: e.target.name, value: e.target.value.trim() })
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isValidate(errors)) {
      return;
    }
    dispatch(login(username, password));
  };

  const isValidate = (errors) => {
    const errorsValues = Object.values(errors);
    const index = errorsValues.find((error) => error === true);
    if (!!index) {
      dispatch(toogleInput('username'));
      dispatch(toogleInput('password'));
    }
    return !!index;
  };

  return (
    <Wrapper>
      <Logo src={logo} alt="logo" />
      <Title>Reporting Dashboard</Title>
      {isLoginError && (
        <Typography color="error" style={{ marginBottom: `10px` }}>
          Username or password is incorrect.
        </Typography>
      )}
      <LoginForm onSubmit={handleSubmit}>
        <TextField
          id="username"
          name="username"
          placeholder="Username"
          value={username}
          onChange={handleChange}
          disabled={isLoading}
          fullWidth
          data-testid="username"
          variant="outlined"
          size="small"
          autoFocus
        />
        <TextField
          id="password"
          name="password"
          placeholder="Password"
          type="password"
          value={password}
          onChange={handleChange}
          disabled={isLoading}
          fullWidth
          data-testid="password"
          variant="outlined"
          size="small"
        />
        <Button
          type="submit"
          disabled={isLoading}
          fullWidth
          variant="contained"
          color="primary"
        >
          Login
        </Button>
      </LoginForm>
    </Wrapper>
  );
}

export default Login;
