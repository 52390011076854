import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 250px;
  @media (max-width: 768px) {
    width: 90%;
  }
  & > div {
    margin-bottom: 10px;
  }
`;

export const Title = styled.h1`
  margin: 15px 0;
  font-size: 1.6em;
  color: var(--green);
`;

export const Logo = styled.img`
  width: 120px;
  border-radius: 5px;
  user-select: none;
`;

export const Error = styled.span`
  color: var(--error);
  margin-bottom: 10px;
`;
