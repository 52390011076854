import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

export const costConsumptionUploadFilterSlice = createSlice({
  name: 'costConsumptionUploadFilter',
  initialState: {
    fromDate: new Date(),
    toDate: new Date(),
  },
  reducers: {
    changeFromDate: (state, action) => {
      state.fromDate = action.payload.value;

      const isSameMonthAsFromDate = moment(state.fromDate).isSame(moment(state.toDate), 'month');
      const isSameMonthAsToday = moment(state.fromDate).isSame(new Date(), 'month');

      if (!isSameMonthAsFromDate) {
        if (!isSameMonthAsToday) {
          state.toDate = moment(state.fromDate).endOf('month').toDate();
        } else {
          state.toDate = moment().date() === 1
          ? moment().subtract(2, 'days').endOf('month').toDate()
          : moment().subtract(1, 'days').toDate();
        }
      }
    },
    changeToDate: (state, action) => {
      state.toDate = action.payload.value;

      const isSameMonthAsToDate = moment(state.toDate).isSame(moment(state.fromDate), 'month');
      const isSameMonthAsToday = moment(state.toDate).isSame(new Date(), 'month');

      if (!isSameMonthAsToDate) {
        if (!isSameMonthAsToday) {
          state.fromDate = moment(state.toDate).startOf('month').toDate();
        } else {
          state.fromDate = moment().date() === 1
          ? moment().subtract(2, 'days').startOf('month').toDate()
          : moment().startOf('month').toDate();
        }
      }
    },
  },
});

export const { changeFromDate, changeToDate } =
  costConsumptionUploadFilterSlice.actions;

export const selectFromDate = (state) => state.costConsumptionUploadFilter.fromDate;
export const selectToDate = (state) => state.costConsumptionUploadFilter.toDate;

export default costConsumptionUploadFilterSlice.reducer;
