import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import {
  selectIsFilterLoading,
  selectIsFilterError,
  fetchResellers,
  selectResellerList,
  selectFilter,
} from '../../components/DashboardFilter/dashboardFilterSlice';

import useAuthorities from '../../hooks/useAuthorities';
import {
  selectIsLoading,
  selectIsError,
  selectData,
  selectErrorMessage,
  fetchData,
  clearData,
} from './nonDisaggregatedSlice';
import Loader from '../../components/Loader';
import { ErrorText, Text } from '../../components/typography';
import DashboardFilter from '../../components/DashboardFilter';
import NonDisaggregatedCards from './NonDisaggregatedCards';
import NonDisaggregatedTable from './NonDisaggregatedTable';
import { EMPTY_RESELLER_OPTION, MESSAGES } from '../../utils/constants';
import PageTitle from '../../components/PageTitle';

function NonDisaggregated() {
  const { isAdminOrVolta } = useAuthorities();

  const resellerList = useSelector(selectResellerList);
  const isFilterLoading = useSelector(selectIsFilterLoading);
  const isFilterError = useSelector(selectIsFilterError);

  const filter = useSelector(selectFilter);
  const isLoading = useSelector(selectIsLoading);
  const isError = useSelector(selectIsError);
  const errorMessage = useSelector(selectErrorMessage);
  const data = useSelector(selectData);
  const dispatch = useDispatch();

  const selectedFilters = [
    ...(filter?.ninetyPercentConnected ? ['Devices connected > 90% of time'] : []),
    ...(filter?.deviceType ? [`Device type (${filter.deviceType})`] : []),
  ];

  useEffect(() => {
    if (!resellerList) {
      dispatch(fetchResellers());
    }
  }, [dispatch, resellerList]);

  useEffect(() => {
    dispatch(clearData());
  }, [dispatch, clearData]);

  useEffect(() => {
    if (isAdminOrVolta() && filter.reseller.name === EMPTY_RESELLER_OPTION) {
      return;
    }
    dispatch(
      fetchData(
        moment(filter.date).format('YYYY-MM'),
        filter.reseller.integratorId,
        filter.ninetyPercentConnected,
        filter.deviceType,
      )
    );
  }, [dispatch, filter]);

  return (
    <>
      {isFilterLoading && !isFilterError && <Loader data-testid="loader" />}

      {!isFilterLoading && isFilterError && (
        <ErrorText>{MESSAGES.SERVER_ERROR}</ErrorText>
      )}

      {!isFilterLoading && !isFilterError && resellerList && (
        <>
          <PageTitle 
            title={"Non-disaggregated Load Dashboard"}
            tooltip={selectedFilters.length > 0 ? "Note that the there's filters applied to the data you are seeing" : null}
            filters={selectedFilters}
          >
            <DashboardFilter showNinetyPercentFilter />
          </PageTitle>

          {isLoading && !isError && <Loader data-testid="loader" />}

          {!isLoading && isError && <ErrorText>{errorMessage}</ErrorText>}
          {isAdminOrVolta() &&
            filter.reseller &&
            filter.reseller.name === EMPTY_RESELLER_OPTION && (
              <Text>Please, select a reseller.</Text>
            )}

          {!isLoading && !isError && data && (
            <>
              <NonDisaggregatedCards data={data} />
              <NonDisaggregatedTable data={data} />
            </>
          )}
        </>
      )}
    </>
  );
}

export default NonDisaggregated;
