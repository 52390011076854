import { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Collapse from '@material-ui/core/Collapse';

import { CustomTableCell, SubtableCell, SubtableWrapper } from './styles';

function Row({ row, showElectricReadings }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Fragment>
      <TableRow>
        {showElectricReadings && (
          <CustomTableCell $showElectricReadings={showElectricReadings}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setIsOpen((isOpen) => !isOpen)}
            >
              {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </CustomTableCell>
        )}
        <CustomTableCell
          align="center"
          $showElectricReadings={showElectricReadings}
        >
          {row.timestampUtc}
        </CustomTableCell>
        <CustomTableCell
          align="center"
          $showElectricReadings={showElectricReadings}
        >
          {row.energyReading.totalConsumptionWh}
        </CustomTableCell>
        <CustomTableCell
          align="center"
          $showElectricReadings={showElectricReadings}
        >
          {row.energyReading.readingTimeUtc}
        </CustomTableCell>
        <CustomTableCell
          align="left"
          $showElectricReadings={showElectricReadings}
        >
          {row.electricityCharges.billingMode}
        </CustomTableCell>
        <CustomTableCell
          align="center"
          $showElectricReadings={showElectricReadings}
        >
          {row.electricityCharges.tariff.activeTariffPriceMill}
        </CustomTableCell>
        <CustomTableCell
          align="center"
          $showElectricReadings={showElectricReadings}
        >
          {row.electricityCharges.tariff.nextTariffPresent}
        </CustomTableCell>
        <CustomTableCell
          align="center"
          $showElectricReadings={showElectricReadings}
        >
          {row.electricityCharges.tariff.nextTariffPriceMill}
        </CustomTableCell>
        <CustomTableCell
          align="center"
          $showElectricReadings={showElectricReadings}
        >
          {row.electricityCharges.tariff.nextTariffStartTime}
        </CustomTableCell>
        {row.electricityCharges.cost.map((item) => (
          <Fragment key={item.period}>
            <CustomTableCell
              align="center"
              $showElectricReadings={showElectricReadings}
            >
              {item.amountMill}
            </CustomTableCell>
            <CustomTableCell
              align="center"
              $showElectricReadings={showElectricReadings}
            >
              {item.energyWh}
            </CustomTableCell>
          </Fragment>
        ))}
      </TableRow>
      {showElectricReadings && (
        <TableRow>
          <SubtableCell>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
              <SubtableWrapper>
                <Table size="small" aria-label="electric readings table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Active Power (W)</TableCell>
                      <TableCell align="center">Phase Shift</TableCell>
                      <TableCell align="center">Power Factor</TableCell>
                      <TableCell align="center">UTC Timestamp</TableCell>
                      {Array.from({ length: 10 }, (_, index) => (
                        <TableCell key={`header-${index}`} />
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.electricReadings.map((electricReading) => (
                      <TableRow key={electricReading.timestampUtc}>
                        <TableCell align="center">
                          {electricReading.activePowerW}
                        </TableCell>
                        <TableCell align="center">
                          {electricReading.phaseShift}
                        </TableCell>
                        <TableCell align="center">
                          {electricReading.powerFactor}
                        </TableCell>
                        <TableCell align="center">
                          {electricReading.timestampUtc}
                        </TableCell>
                        {Array.from({ length: 10 }, (_, index) => (
                          <TableCell key={`body-${index}`} />
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </SubtableWrapper>
            </Collapse>
          </SubtableCell>
        </TableRow>
      )}
    </Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.object.isRequired,
  showElectricReadings: PropTypes.bool.isRequired,
};

export default Row;
