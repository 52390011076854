import { createSlice } from '@reduxjs/toolkit';

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState: {
    isSidebarOpen: localStorage.getItem('sidebar')
      ? JSON.parse(localStorage.getItem('sidebar'))
      : true,
  },
  reducers: {
    changeOpenSidebar: (state, action) => {
      state.isSidebarOpen = action.payload;
    },
  },
});

export const { changeOpenSidebar } = sidebarSlice.actions;

export const selectIsSidebarOpen = (state) => state.sidebar.isSidebarOpen;

export default sidebarSlice.reducer;
