import PropTypes from 'prop-types';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import MUITypography from '@material-ui/core/Typography';
import MUIDivider from '@material-ui/core/Divider';
import ReactTooltip from 'react-tooltip';
import { BsFillQuestionDiamondFill as InfoIcon } from 'react-icons/bs';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';

const ContainerGrid = styled(Grid)`
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const Typography = styled(MUITypography)`
  display: flex;
  align-items: center;
  margin-top: 15px;
`;

const Divider = styled(MUIDivider)`
  margin: 20px 0;
`;

const BottomContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
const SearchButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(green[400]),
    backgroundColor: green[400],
    '&:hover': {
      backgroundColor: green[800],
    },
  },
}))(Button);
const SelectedFiltersContainer = styled.div`
  display: flex;
`;
const SelectedFiltersTitle = styled(MUITypography)`
  strong {
    font-weight: 600;
  }
`;
const SelectedFilter = styled(MUITypography)``;

function PageTitle({ title, tooltip = null, children = null, filters = null, onSearch = null }) {
  const displayBottomContainer = (filters && filters.length > 0) || onSearch;
  return (
    <div>
      <ContainerGrid container display="flex" justify="space-between">
        <Grid item>
          <Typography variant="h6">
            {title}
            {tooltip ? (
              <InfoIcon
                fill="#388e3c"
                style={{ marginLeft: '10px' }}
                data-tip
                data-for="pageTitleTooltip"
              />
            ) : null}
          </Typography>
        </Grid>
        {children && <Grid item>{children}</Grid>}
        {tooltip ? (
          <ReactTooltip
            id="pageTitleTooltip"
            place="top"
            type="dark"
            effect="solid"
          >
            <div>{tooltip}</div>
          </ReactTooltip>
        ) : null}
      </ContainerGrid>
      {displayBottomContainer && (
        <BottomContainer>
          {filters && filters.length > 0 && (
            <SelectedFiltersContainer>
              <SelectedFiltersTitle><strong>Selected filters:</strong>&nbsp;</SelectedFiltersTitle>
              {filters.map((filter, index) => (
                  <SelectedFilter key={`selected-filter-${index}`}>
                    {filter}{index < filters.length-1 && <>,&nbsp;</>}
                  </SelectedFilter>
                )
              )}
            </SelectedFiltersContainer>
          )}
          {onSearch && (
            <SearchButton
              style={{ padding: '5px 10px', marginTop: 10 }}
              variant="contained"
              onClick={() => onSearch()}
            >
              Search
            </SearchButton>
          )}
        </BottomContainer>
      )}
      <Divider />
    </div>
  );
}

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  filters: PropTypes.array,
  searchButton: PropTypes.func,
};

export default PageTitle;
