import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useSpring } from 'react-spring';

import { selectIsSidebarOpen } from '../../../components/Sidebar/sidebarSlice';
import { Wrapper, ArrowCell } from './styles';
import Row from './Row';

function PacketMessagesTable({ data, showElectricReadings }) {
  const isSidebarOpen = useSelector(selectIsSidebarOpen);

  const props = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
  });

  return (
    <Wrapper $isSidebarOpen={isSidebarOpen} style={props}>
      <Table stickyHeader aria-label="packet messages table">
        <TableHead>
          <TableRow>
            {showElectricReadings && <ArrowCell />}
            <TableCell align="center">UTC Timestamp</TableCell>
            <TableCell align="center">Total Consumption (Wh)</TableCell>
            <TableCell align="center">UTC Reading Time</TableCell>
            <TableCell align="center">Billing Mode</TableCell>
            <TableCell align="center">Active Tariff Price (Mill)</TableCell>
            <TableCell align="center">Next Tariff Present</TableCell>
            <TableCell align="center">Next Tariff Price (Mill)</TableCell>
            <TableCell align="center">Next Tariff Start Time</TableCell>
            <TableCell align="center">Daily Cost (Mill)</TableCell>
            <TableCell align="center">Daily Consumption (Wh)</TableCell>
            <TableCell align="center">Weekly Cost (Mill)</TableCell>
            <TableCell align="center">Weekly Consumption (Wh)</TableCell>
            <TableCell align="center">Monthly Cost (Mill)</TableCell>
            <TableCell align="center">Monthly Consumption (Wh)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <Row
              key={row.timestampUtc}
              row={row}
              showElectricReadings={showElectricReadings}
            />
          ))}
        </TableBody>
      </Table>
    </Wrapper>
  );
}

PacketMessagesTable.propTypes = {
  data: PropTypes.array.isRequired,
  showElectricReadings: PropTypes.bool.isRequired,
};

export default PacketMessagesTable;
