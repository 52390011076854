import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSpring, animated } from 'react-spring';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Cell,
} from 'recharts';

import BarLabel from './BarLabel';
import ChartTooltip from '../../../components/ChartTooltip';
import { Wrapper } from './styles';
import { ErrorText } from '../../../components/typography';
import { DEVICE_STATUS, MESSAGES } from '../../../utils/constants';

function SubscriptionChart({ data }) {
  const [isHover, setIsHover] = useState(false);
  const [tooltipData, setTooltipData] = useState(null);
  const isAnimationActive = useRef(true);

  useEffect(() => {
    if (!isHover) {
      isAnimationActive.current = true;
    }
  }, [isHover]);

  const parseTooltipData = (index, barName) => {
    const monthData = { ...data[index] };

    if (barName === 'connected') {
      return [
        {
          id: 'connected',
          title: 'Online',
          color: '#81c784',
          ...monthData.connected,
        },
        {
          id: 'reactivated',
          title: 'Reactivated',
          color: '#4f91a5',
          ...monthData.reactivated,
        },
        {
          id: 'activated',
          title: 'Newly installed',
          color: '#8E9863',
          ...monthData.activated,
        },
      ];
    } else {
      return [
        {
          id: 'droppedOut',
          title: 'Dropped',
          color: '#ff0033',
          ...monthData.droppedOut,
        },
      ];
    }
  };

  const legendFormatter = (value) => {
    return DEVICE_STATUS[value];
  };

  const legendPayload = [
    {
      value: 'connected',
      type: 'rect',
      color: '#81c784',
    },
    {
      value: 'activated',
      type: 'rect',
      color: '#8E9863',
    },
    {
      value: 'reactivated',
      type: 'rect',
      color: '#4f91a5',
    },
    {
      value: 'droppedOut',
      type: 'rect',
      color: '#ff0033',
    },
  ];

  const isChartEmpty = (data) => {
    return data.every((item) => {
      return (
        (!item.activated || item.activated.value < 1) &&
        (!item.connected || item.connected.value < 1) &&
        (!item.droppedOut || item.droppedOut.value < 1) &&
        (!item.reactivated || item.reactivated.value < 1)
      );
    });
  };

  const getHighestValue = (data) => {
    let highestValue = 0;

    data.map((item) => {
      if (
        item.activated &&
        item.activated.value &&
        item.activated.value > highestValue
      ) {
        highestValue = item.activated.value;
      }
      if (
        item.connected &&
        item.connected.value &&
        item.connected.value > highestValue
      ) {
        highestValue = item.connected.value;
      }
      if (
        item.droppedOut &&
        item.droppedOut.value &&
        item.droppedOut.value > highestValue
      ) {
        highestValue = item.droppedOut.value;
      }
      if (
        item.reactivated &&
        item.reactivated.value &&
        item.reactivated.value > highestValue
      ) {
        highestValue = item.reactivated.value;
      }

      return true;
    });

    return highestValue;
  };

  const isThereAnyBarWithValue = (item) => {
    return data.some((elem) => elem[item] && elem[item].value > 0);
  };

  const isThereOnlyOnlineBar = () => {
    const bool = data.every(
      (item) =>
        item.activated &&
        item.activated.value <= 0 &&
        item.reactivated &&
        item.reactivated.value <= 0
    );
    return bool;
  };

  const props = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
  });
  const AnimatedWrapper = animated(Wrapper);

  return (
    <>
      {isChartEmpty(data) && <ErrorText>{MESSAGES.NO_DATA}</ErrorText>}
      {!isChartEmpty(data) && (
        <AnimatedWrapper style={props}>
          <h4>Devices</h4>
          <ResponsiveContainer>
            <BarChart data={data} barSize={70}>
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <XAxis dataKey="yearMonth" tickLine={false} />
              <YAxis allowDecimals={false} />
              <Legend formatter={legendFormatter} payload={legendPayload} />
              {isThereAnyBarWithValue('connected') &&
                (!isHover || isThereOnlyOnlineBar()) && (
                  <Bar
                    dataKey="connected.value"
                    fill="#81c784"
                    label={
                      <BarLabel
                        onMouseEnter={(data, index) =>
                          setTooltipData(parseTooltipData(index, 'connected'))
                        }
                        minValue={getHighestValue(data) / 6}
                      />
                    }
                    name="connected"
                    onMouseEnter={(data, index) => {
                      isAnimationActive.current = false;
                      setTooltipData(parseTooltipData(index, 'connected'));
                      setIsHover(true);
                    }}
                    onMouseLeave={() => {
                      setTooltipData(null);
                    }}
                    isAnimationActive={isAnimationActive.current}
                  ></Bar>
                )}
              {isThereAnyBarWithValue('activated') > 0 && isHover && (
                <Bar
                  dataKey="activated.value"
                  fill="#8E9863"
                  name="activated"
                  onMouseEnter={(data, index) => {
                    setTooltipData(parseTooltipData(index, 'connected'));
                    setIsHover(true);
                  }}
                  onMouseLeave={() => {
                    setTooltipData(null);
                    setIsHover(false);
                  }}
                  stackId="online"
                  isAnimationActive={isAnimationActive.current}
                />
              )}
              {isThereAnyBarWithValue('reactivated') && isHover && (
                <Bar
                  dataKey="reactivated.value"
                  fill="#4f91a5"
                  name="reactivated"
                  onMouseEnter={(data, index) => {
                    setTooltipData(parseTooltipData(index, 'connected'));
                    setIsHover(true);
                  }}
                  onMouseLeave={() => {
                    setTooltipData(null);
                    setIsHover(false);
                  }}
                  stackId="online"
                  isAnimationActive={isAnimationActive.current}
                />
              )}
              {(isThereAnyBarWithValue('activated') ||
                isThereAnyBarWithValue('reactivated')) &&
                isHover && (
                  <Bar
                    dataKey="remainder.value"
                    fill="transparent"
                    name="remainder"
                    onMouseEnter={(data, index) => {
                      setTooltipData(parseTooltipData(index, 'connected'));
                      setIsHover(true);
                    }}
                    onMouseLeave={() => {
                      setTooltipData(null);
                      setIsHover(false);
                    }}
                    stackId="online"
                    isAnimationActive={isAnimationActive.current}
                  >
                    {data.map((entry, index) => {
                      return (
                        <Cell key={index} stroke="#00e676" strokeWidth={0.7} />
                      );
                    })}
                  </Bar>
                )}
              {isThereAnyBarWithValue('droppedOut') && (
                <Bar
                  dataKey="droppedOut.value"
                  fill="#ff0033"
                  label={
                    <BarLabel
                      onMouseEnter={(data, index) =>
                        setTooltipData(parseTooltipData(index, 'droppedOut'))
                      }
                      minValue={getHighestValue(data) / 6}
                    />
                  }
                  name="droppedOut"
                  onMouseEnter={(data, index) => {
                    isAnimationActive.current = false;
                    setTooltipData(parseTooltipData(index, 'droppedOut'));
                  }}
                  onMouseLeave={() => setTooltipData(null)}
                  isAnimationActive={isAnimationActive.current}
                />
              )}
            </BarChart>
          </ResponsiveContainer>
          {tooltipData && <ChartTooltip tooltipData={tooltipData} />}
        </AnimatedWrapper>
      )}
    </>
  );
}

SubscriptionChart.propTypes = {
  data: PropTypes.array.isRequired,
};

export default SubscriptionChart;
