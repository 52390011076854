import { createSlice } from '@reduxjs/toolkit';
import {
  propertyTypeOptions,
  personAmountOptions,
  bedroomsAmountOptions,
  homeTypeOptions,
} from '../../../utils/properties';
import { getAllAppliancesAndSetActiveTo } from '../../../utils/appliances';

export const overviewFilterSlice = createSlice({
  name: 'overviewFilter',
  initialState: {
    propertyFilter: {
      propertyType: propertyTypeOptions[0],
      homeSize: [0, 510],
      personAmount: personAmountOptions[0],
      bedroomsAmount: bedroomsAmountOptions[0],
      homeType: homeTypeOptions[0],
    },
    appliancesFilter: getAllAppliancesAndSetActiveTo(true),
  },
  reducers: {
    changeFilter: (state, action) => {
      state.propertyFilter[action.payload.name] = action.payload.option;
    },
    changeSliderFilter: (state, action) => {
      const array = [action.payload.min, action.payload.max];
      state.propertyFilter[action.payload.name] = array;
    },
    clearFilter: (state) => {
      state.propertyFilter = {
        propertyType: propertyTypeOptions[0],
        homeSize: [0, 510],
        personAmount: personAmountOptions[0],
        bedroomsAmount: bedroomsAmountOptions[0],
        homeType: homeTypeOptions[0],
      };
      state.appliancesFilter = getAllAppliancesAndSetActiveTo(true);
    },
    changeAppliancesFilter: (state, action) => {
      if (action.payload.name === 'all' && action.payload.value === true) {
        state.appliancesFilter = getAllAppliancesAndSetActiveTo(true);
      }

      if (action.payload.name === 'all' && action.payload.value === false) {
        state.appliancesFilter = getAllAppliancesAndSetActiveTo(false);
      }

      if (
        action.payload.value === false &&
        state.appliancesFilter.all.isActive === true
      ) {
        state.appliancesFilter.all.isActive = false;
      }

      state.appliancesFilter[action.payload.name].isActive =
        action.payload.value;

      const keysWithouAll = Object.keys(state.appliancesFilter);
      keysWithouAll.shift();
      if (
        keysWithouAll.every(
          (key) => state.appliancesFilter[key].isActive === true
        )
      ) {
        state.appliancesFilter.all.isActive = true;
      }
    },
  },
});

export const {
  changeFilter,
  changeSliderFilter,
  clearFilter,
  changeAppliancesFilter,
} = overviewFilterSlice.actions;

export const selectPropertyFilter = (state) =>
  state.overviewFilter.propertyFilter;
export const selectAppliancesFilter = (state) =>
  state.overviewFilter.appliancesFilter;

export default overviewFilterSlice.reducer;
