import styled from 'styled-components';

export const Wrapper = styled.div`
  width: ${({ isSidebarOpen }) => (isSidebarOpen ? '258px' : '56px')};
  transition: width 0.2s;
  overflow-x: hidden;
  display: flex;
  height: 100vh;
`;

export const FixedComponent = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: ${({ isSidebarOpen }) => (isSidebarOpen ? '258px' : '56px')};
  transition: width 0.2s;
  overflow-x: hidden;
  display: flex;
  height: 100vh;
`;
