import styled from 'styled-components';
import MUIListItem from '@material-ui/core/ListItem';

export const NestedListItem = styled(MUIListItem)`
  padding-left: 70px;
  padding-right: 30px;
  padding: 4px 30px 4px 70px;
  opacity: 0.7;
  transition: background 0.2s, opacity 0.2s;

  &:hover,
  &.active {
    background: #36893a;
    opacity: 1;
  }
`;

export const PopoverWrapper = styled.div`
  padding: 5px 0;
  display: flex;
  flex-direction: column;

  & > a {
    font-size: 0.9em;
  }
`;
