import PropTypes from 'prop-types';
import { useSpring, animated } from 'react-spring';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { getOthersData } from '../../../utils/parseData';
function NonDisaggregatedTable({ data }) {
  const tableRows = getOthersData(data);

  const props = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
  });
  const AnimatedTableContainer = animated(TableContainer);

  return (
    <AnimatedTableContainer component={Paper} style={props}>
      <Table aria-label="table">
        <TableHead>
          <TableRow>
            <TableCell align="left" style={{ width: '200px' }}>
              Others
            </TableCell>
            <TableCell align="center">% devices </TableCell>
            <TableCell align="center"># devices</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableRows.map((row) => (
            <TableRow key={row.disconnection}>
              <TableCell align="left">{row.disconnection}</TableCell>
              <TableCell align="center">{row.percentage}</TableCell>
              <TableCell align="center">{row.numberOfDevices}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </AnimatedTableContainer>
  );
}

NonDisaggregatedTable.propTypes = {
  data: PropTypes.object.isRequired,
};

export default NonDisaggregatedTable;
