export const getToken = () => {
  const token = localStorage.getItem('x-auth-token');
  return token;
};

export const setToken = (token) => {
  localStorage.setItem('x-auth-token', token);
};

export const removeToken = () => {
  localStorage.removeItem('x-auth-token');
};

export const getAuthorities = () => {
  const authorities = JSON.parse(localStorage.getItem('authorities'));
  return authorities;
};

export const setAuthorities = (authorities) => {
  localStorage.setItem('authorities', JSON.stringify(authorities));
};

export const removeAuthorities = () => {
  localStorage.removeItem('authorities');
};

export const getCurrentUser = () => {
  const currentUser = localStorage.getItem('currentUser');
  return currentUser;
};

export const setCurrentUser = (currentUser) => {
  localStorage.setItem('currentUser', currentUser);
};

export const removeCurrentUser = () => {
  localStorage.removeItem('currentUser');
};

export const getReseller = () => {
  const authorities = JSON.parse(localStorage.getItem('reseller'));
  return authorities;
};

export const setReseller = (reseller) => {
  localStorage.setItem('reseller', JSON.stringify(reseller));
};

export const removeReseller = () => {
  localStorage.removeItem('reseller');
};
