import { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FilterListIcon from '@material-ui/icons/FilterList';
import Popover from '@material-ui/core/Popover';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { PopoverWrapper, GridContainer } from './styles';
import {
  selectFromDate,
  selectToDate,
  selectShowFilter,
  changeFromDate,
  changeToDate,
  changeShowFilter,
} from './subscriptionFilterSlice';
import {
  selectFilter as selectResellerFilter,
  selectResellerList,
  changeFilter as changeResellerFilter,
} from '../../../components/DashboardFilter/dashboardFilterSlice';
import { selectReseller } from '../../../pages/Login/loginSlice';
import useAuthorities from '../../../hooks/useAuthorities';
import { EMPTY_RESELLER_OPTION } from '../../../utils/constants';
import { deviceTypeOptions} from '../../../utils/properties';

function SubscriptionFilter() {
  const { isAdminOrVolta } = useAuthorities();

  const resellerFilter = useSelector(selectResellerFilter);
  const resellerList = useSelector(selectResellerList);
  const reseller = useSelector(selectReseller);
  const fromDate = useSelector(selectFromDate);
  const toDate = useSelector(selectToDate);
  const showFilter = useSelector(selectShowFilter);
  const dispatch = useDispatch();
  const isFirstRender = useRef(true);

  const [anchorEl, setAnchorEl] = useState(false);

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up('sm'));

  const handleChangeReseller = (e, value) => {
    isFirstRender.current = false;
    dispatch(changeResellerFilter({ name: 'reseller', value }));
  };

  const handleChangeFromDate = (value) => {
    isFirstRender.current = false;
    dispatch(changeFromDate({ name: 'fromDate', value }));
  };

  const handleChangeToDate = (value) => {
    isFirstRender.current = false;
    dispatch(changeToDate({ name: 'toDate', value }));
  };

  const handleChangeShowFilter = (event, value) => {
    dispatch(changeShowFilter({ name: event.target.name, value: value }));
  };

  const handleChangeDeviceType = (event) => {
    dispatch(changeResellerFilter({ name: 'deviceType', value: event.target.value }));
  };

  const isResellerSelected = () => {
    if (isAdminOrVolta()) {
      return resellerFilter.reseller.name === EMPTY_RESELLER_OPTION;
    }
    return false;
  };

  const calculateMinDate = (date) => {
    if (!date) {
      return null;
    }
    return moment(date).startOf('month').toDate();
  };

  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const popoverBody = (
    <PopoverWrapper>
      <Grid item>Filter options</Grid>
      <Grid item style={{ marginTop: 5, marginBottom: 5 }} data-testid="deviceTypeFilter">
        <InputLabel id="deviceTypeLabel" shrink={true}>Device Type</InputLabel>
        <Select
          labelId="deviceTypeLabel"
          id="deviceType"
          name="deviceType"
          value={resellerFilter.deviceType}
          onChange={handleChangeDeviceType}
          style={{ minWidth: 250, width: '100%' }}
        >
          {deviceTypeOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={showFilter.connected}
                onChange={handleChangeShowFilter}
                name="connected"
                style={{ color: '#81c784' }}
              />
            }
            label="Connected"
          />
        </FormGroup>
      </Grid>
      <Grid item>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={showFilter.droppedOut}
                onChange={handleChangeShowFilter}
                name="droppedOut"
                style={{ color: '#ff0033' }}
              />
            }
            label="Dropped out"
          />
        </FormGroup>
      </Grid>
    </PopoverWrapper>
  );

  return (
    <>
      <GridContainer container spacing={2}>
        <Grid item data-testid="filterOpener">
          <Button
            color="default"
            startIcon={<FilterListIcon />}
            onClick={handleOpenPopover}
            style={{ marginTop: '12px' }}
          >
            Filter
          </Button>
        </Grid>
        {isAdminOrVolta() && (
          <Grid item>
            <Autocomplete
              id="reseller-select"
              options={resellerList}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} label="Reseller" />
              )}
              onChange={handleChangeReseller}
              disableClearable
              style={{ width: sm ? '200px' : '100%' }}
              defaultValue={() => {
                if (resellerFilter.reseller.name === EMPTY_RESELLER_OPTION) {
                  return null;
                }
                return resellerFilter.reseller;
              }}
            />
          </Grid>
        )}
        <Grid item>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              format="MMM yyyy"
              views={['year', 'month']}
              openTo="month"
              autoOk
              id="from-date"
              label="From date"
              value={fromDate}
              onChange={handleChangeFromDate}
              minDate={
                reseller
                  ? calculateMinDate(reseller.createdAt)
                  : calculateMinDate(resellerFilter.reseller.createdAt)
              }
              disableFuture
              disabled={isResellerSelected()}
              style={{ width: sm ? '90px' : '100%' }}
              variant="inline"
              minDateMessage=""
              maxDateMessage=""
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              format="MMM yyyy"
              views={['year', 'month']}
              openTo="month"
              autoOk
              id="to-date"
              label="To date"
              value={toDate}
              onChange={handleChangeToDate}
              minDate={fromDate}
              disableFuture
              disabled={isResellerSelected()}
              style={{ width: sm ? '90px' : '100%' }}
              variant="inline"
              minDateMessage=""
              maxDateMessage=""
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </GridContainer>
      <Popover
        id="popover"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {popoverBody}
      </Popover>
    </>
  );
}

export default SubscriptionFilter;
