import styled, { createGlobalStyle } from 'styled-components';
import Paper from '@material-ui/core/Paper';

export const GlobalStyle = createGlobalStyle`
  body {
    min-height: 100vh;
    display: flex;
    --green: #6AB42D;
    --white: #fff;
    --gray: #eee;
    overflow-x: hidden;
    overflow-y: scroll;
  };

  #root {
    flex: 1;
    display: flex;
  }

  * {
    font-family: Inter, Roboto, "Helvetica Neue", Arial, sans-serif !important;
  }

  a {
    color: inherit;
  }

  h1, h2, h3, h4, h5, h6, p {
    margin: 0;
    padding: 0;
  }

  button {
    outline: none;
    background: inherit;
    color: #fff;
    border: none;
    cursor: pointer;
  }

  .MuiTableContainer-root {
    @media (max-width: 600px) {
      max-width: calc(100vw - 40px);
    }
  }

  .MuiTable-root .MuiTableBody-root .cost-consumption-detail {
    td {
      padding: 0;

      
    }
  }

  .MuiTable-root .MuiTableBody-root .cost-consumption-row {
    td {
      border: none;
      padding: 8px 16px;

      &:nth-child(1) {
        text-align: center;
      }

      &:nth-child(2).disabled {
        color: rgba(0, 0, 0, 0.47);
      }
    }

    &.even {
      background: #f0f0f4;
    }
  }

  .MuiTable-root {
    overflow-x: auto;
  }
`;

export const Layout = styled.div`
  flex: 1;
  display: flex;
`;

export const Page = styled(Paper)`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #f0f2f5;
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 33px 48px;
  overflow: hidden;

  @media (max-width: 600px) {
    padding: 5px 20px;
  }
`;
