import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

import loginReducer from './pages/Login/loginSlice';
import sidebarReducer from './components/Sidebar/sidebarSlice';
import dashboardFilterReducer from './components/DashboardFilter/dashboardFilterSlice';
import overviewReducer from './pages/Overview/overviewSlice';
import overviewFilterReducer from './pages/Overview/OverviewFilter/overviewFilterSlice';
import disaggregatedReducer from './pages/Disaggregated/disaggregatedSlice';
import reportedReducer from './pages/Reported/reportedSlice';
import nonDisaggregatedReducer from './pages/NonDisaggregated/nonDisaggregatedSlice';
import dataQualityReducer from './pages/DataQuality/dataQualitySlice';
import subscriptionReducer from './pages/Subscription/subscriptionSlice';
import subscriptionFilterReducer from './pages/Subscription/SubscriptionFilter/subscriptionFilterSlice';
import costConsumptionUploadReducer from './pages/CostConsumptionUpload/costConsumptionUploadSlice';
import costConsumptionUploadFilterReducer from './pages/CostConsumptionUpload/CostConsumptionUploadFilter/costConsumptionUploadFilterSlice';
import costConsumptionResultsReducer from './pages/CostConsumptionResults/costConsumptionResultsSlice';
import packetMessagesSliceReducer from './pages/PacketMessages/packetMessagesSlice';

import usersReducer from './pages/Users/usersSlice';
import resellersAccessReducer from './pages/ResellersAccess/resellersAccessSlice';

export const rootReducer = {
  login: loginReducer,
  sidebar: sidebarReducer,
  dashboardFilter: dashboardFilterReducer,
  overview: overviewReducer,
  overviewFilter: overviewFilterReducer,
  disaggregated: disaggregatedReducer,
  reported: reportedReducer,
  nonDisaggregated: nonDisaggregatedReducer,
  dataQuality: dataQualityReducer,
  subscription: subscriptionReducer,
  costConsumptionUpload: costConsumptionUploadReducer,
  costConsumptionUploadFilter: costConsumptionUploadFilterReducer,
  costConsumptionResults: costConsumptionResultsReducer,
  subscriptionFilter: subscriptionFilterReducer,
  packetMessages: packetMessagesSliceReducer,
  users: usersReducer,
  resellersAccess: resellersAccessReducer,
};

const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false,
});

export default configureStore({
  reducer: rootReducer,
  middleware: customizedMiddleware,
});
