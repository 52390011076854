import { useEffect, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import {
  selectIsFilterLoading,
  selectIsFilterError,
  fetchResellers,
  selectResellerList,
  selectFilter as selectResellerFilter,
} from '../../components/DashboardFilter/dashboardFilterSlice';
import useAuthorities from '../../hooks/useAuthorities';
import {
  selectIsLoading,
  selectIsError,
  selectData,
  selectErrorMessage,
  fetchData,
  clearData,
} from './subscriptionSlice';
import {
  selectFromDate,
  selectToDate,
  selectShowFilter,
} from './SubscriptionFilter/subscriptionFilterSlice';
import { selectFilter } from '../../components/DashboardFilter/dashboardFilterSlice';

import Loader from '../../components/Loader';
import { ErrorText, Text } from '../../components/typography';
import SubscriptionFilter from './SubscriptionFilter';
import SubscriptionChart from './SubscriptionChart';
import { EMPTY_RESELLER_OPTION, MESSAGES } from '../../utils/constants';
import PageTitle from '../../components/PageTitle';

function Reported() {
  const { isAdminOrVolta } = useAuthorities();

  const resellerList = useSelector(selectResellerList);
  const isFilterLoading = useSelector(selectIsFilterLoading);
  const isFilterError = useSelector(selectIsFilterError);

  const resellerFilter = useSelector(selectResellerFilter);
  const fromDate = useSelector(selectFromDate);
  const toDate = useSelector(selectToDate);
  const showFilter = useSelector(selectShowFilter);

  const filter = useSelector(selectFilter);
  const isLoading = useSelector(selectIsLoading);
  const isError = useSelector(selectIsError);
  const errorMessage = useSelector(selectErrorMessage);
  const data = useSelector(selectData);
  const dispatch = useDispatch();

  const selectedFilters = [
    ...(filter?.deviceType ? [`Device type (${filter.deviceType})`] : []),
    ...(showFilter?.connected ? ['Connected devices'] : []),
    ...(showFilter?.droppedOut ? ['Dropped out devices'] : []),
  ];

  useEffect(() => {
    if (!resellerList) {
      dispatch(fetchResellers());
    }
  }, [dispatch, resellerList]);

  useLayoutEffect(() => {
    dispatch(clearData());
  }, [dispatch, clearData]);

  const performSearch = () => {
    if (
      isAdminOrVolta() &&
      resellerFilter.reseller.name === EMPTY_RESELLER_OPTION
    ) {
      return;
    }

    dispatch(
      fetchData({
        fromDate: moment(fromDate).format('YYYY-MM'),
        toDate: moment(toDate).format('YYYY-MM'),
        showFilter,
        integratorId: resellerFilter.reseller.integratorId,
        deviceType: resellerFilter.deviceType,
      })
    );
  };

  const dateIntegerToString = (dateInteger) => {
    const dateString = dateInteger.toString();
    const month = dateString.slice(0, 4);
    const year = dateString.slice(-2);
    const isoDate = `${month}-${year}`;
    const formattedDate = moment(isoDate).format('MMM YYYY');
    return formattedDate;
  };

  const filterData = (data, showFilter) => {
    if (!data) {
      return null;
    }
    let filteredData = [...data];
    filteredData = data.map((item) => {
      const remainderValue =
        item.connected.value -
        (item.activated.value || 0) -
        (item.reactivated.value || 0);
      return {
        yearMonth: dateIntegerToString(item.yearMonth),
        connected: showFilter.connected ? item.connected : null,
        activated: showFilter.connected ? item.activated : null,
        reactivated: showFilter.connected ? item.reactivated : null,
        droppedOut: showFilter.droppedOut ? item.droppedOut : null,
        remainder: {
          value: remainderValue,
          diffLastPeriod: null,
        },
      };
    });
    return filteredData;
  };

  return (
    <>
      {isFilterLoading && !isFilterError && <Loader data-testid="loader" />}

      {!isFilterLoading && isFilterError && (
        <ErrorText>{MESSAGES.SERVER_ERROR}</ErrorText>
      )}

      {!isFilterLoading && !isFilterError && resellerList && (
        <>
          <PageTitle title="Subscription Overview Dashboard" filters={selectedFilters} onSearch={performSearch}>
            <SubscriptionFilter />
          </PageTitle>

          {isLoading && !isError && <Loader data-testid="loader" />}

          {!isLoading && isError && <ErrorText>{errorMessage}</ErrorText>}
          {isAdminOrVolta() &&
            resellerFilter.reseller &&
            resellerFilter.reseller.name === EMPTY_RESELLER_OPTION && (
              <Text>Please, select a reseller.</Text>
            )}

          {!isLoading && !isError && data && (
            <>
              <SubscriptionChart data={filterData(data, showFilter)} />
            </>
          )}
        </>
      )}
    </>
  );
}

export default Reported;
