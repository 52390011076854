import { useState } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { selectIsAuth } from './pages/Login/loginSlice';

import Sidebar from './components/Sidebar';
import Mobilebar from './components/Mobilebar';
import Header from './components/Header';
import { Layout, Page, Content } from './styles';
import Login from './pages/Login';
import Overview from './pages/Overview';
import Disaggregated from './pages/Disaggregated';
import Reported from './pages/Reported';
import NonDisaggregated from './pages/NonDisaggregated';
import DataQuality from './pages/DataQuality';
import Subscription from './pages/Subscription';
// import CostConsumptionUpload from './pages/CostConsumptionUpload';
// import CostConsumptionResults from './pages/CostConsumptionResults';
import PacketMessages from './pages/PacketMessages';
import Users from './pages/Users';
import ResellersAccess from './pages/ResellersAccess';

import useAuthorities from './hooks/useAuthorities';

function App() {
  const isAuth = useSelector(selectIsAuth);
  const { isAdmin } = useAuthorities();
  const location = useLocation();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up('sm'));
  const [isMobilebarOpen, setIsMobilebarOpen] = useState(false);

  const openMobilebar = () => {
    setIsMobilebarOpen(true);
  };

  const closeMobilebar = () => {
    setIsMobilebarOpen(false);
  };

  const protectedRoutes = [
    <Route path="/overview" key="/overview" component={ Overview } />,
    <Route
      path="/disaggregated"
      key="/disaggregated"
      component={ Disaggregated }
    />,
    <Route path="/reported" key="/reported" component={ Reported } />,
    <Route
      path="/non-disaggregated"
      key="/non-disaggregated"
      component={ NonDisaggregated }
    />,
    <Route path="/data-quality" key="/data-quality" component={ DataQuality } />,
    <Route path="/subscription" key="/subscription" component={ Subscription } />,
    // <Route path="/cost-consumption-upload" key="/cost-consumption-upload" component={CostConsumptionUpload} />,
    // <Route path="/cost-consumption-results" key="/cost-consumption-results" component={CostConsumptionResults} />,
    <Route path="/users" key="/users" component={ Users } />,
    ...(isAdmin()
      ? [
        <Route
          path="/resellers-access"
          key="/resellers-access"
          component={ ResellersAccess }
        />,
        <Route
          path="/packet-based-messages"
          key="/packet-based-messages"
          component={ PacketMessages }
        />,
      ]
      : []),
  ];

  return (
    <Layout>
      { location.pathname !== '/login' && sm && <Sidebar /> }
      { location.pathname !== '/login' && !sm && (
        <Mobilebar
          isMobilebarOpen={ isMobilebarOpen }
          closeMobilebar={ closeMobilebar }
        />
      ) }
      <Page>
        { location.pathname !== '/login' && (
          <Header openMobilebar={ openMobilebar } />
        ) }
        <Content>
          <Switch>
            <Route path="/login" component={ Login } />
            { isAuth && protectedRoutes.map((route) => route) }
            <Redirect to="/login" />
          </Switch>
        </Content>
      </Page>
    </Layout>
  );
}

export default App;
