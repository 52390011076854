import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FilterListIcon from '@material-ui/icons/FilterList';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import {
  selectFilter,
  selectResellerList,
  changeFilter,
} from './dashboardFilterSlice';
import { selectReseller } from '../../pages/Login/loginSlice';
import { PopoverWrapper, GridContainer } from './styles';
import useAuthorities from '../../hooks/useAuthorities';
import { EMPTY_RESELLER_OPTION } from '../../utils/constants';
import { deviceTypeOptions } from '../../utils/properties';

function DashboardFilter({
  showDeviceType = true,
  showNinetyPercentFilter = false,
  showMonthPicker = true,
  CustomFilterComponent = null,
}) {
  const { isAdminOrVolta } = useAuthorities();
  const filter = useSelector(selectFilter);
  const resellerList = useSelector(selectResellerList);
  const reseller = useSelector(selectReseller);
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(false);

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up('sm'));

  const handleChangeChecked = (event, value) => {
    dispatch(changeFilter({ name: event.target.name, value: value }));
  };

  const handleChangeDeviceType = (event) => {
    dispatch(changeFilter({ name: 'deviceType', value: event.target.value }));
  };

  const handleChangeReseller = (event, value) => {
    dispatch(changeFilter({ name: 'reseller', value }));
  };

  const handleChangeDate = (value) => {
    dispatch(changeFilter({ name: 'date', value }));
  };

  const isResellerSelected = () => {
    if (isAdminOrVolta()) {
      return filter.reseller.name === EMPTY_RESELLER_OPTION;
    }
    return false;
  };

  const calculateMinDate = (date) => {
    if (!date) {
      return null;
    }
    return moment(date).startOf('month').toDate();
  };

  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const popoverBody = (
    <PopoverWrapper>
      <Grid item>Filter options</Grid>
      {showNinetyPercentFilter && (
        <Grid item>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filter.ninetyPercentConnected}
                  onChange={handleChangeChecked}
                  name="ninetyPercentConnected"
                  color="primary"
                />
              }
              label="Devices connected > 90% of time"
            />
          </FormGroup>
        </Grid>
      )}
      {showDeviceType && (
        <Grid item style={{ marginTop: 5, marginBottom: 5 }} data-testid="deviceTypeFilter">
          <InputLabel id="deviceTypeLabel" shrink={true}>Device Type</InputLabel>
          <Select
            labelId="deviceTypeLabel"
            id="deviceType"
            name="deviceType"
            value={filter.deviceType || 'VOLTAWARE_SENSOR'}
            onChange={handleChangeDeviceType}
            style={{ width: '100%' }}
          >
            {deviceTypeOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      )}
      {CustomFilterComponent}
    </PopoverWrapper>
  );

  return (
    <>
      <GridContainer container spacing={2}>
        {(showNinetyPercentFilter || showDeviceType) && (
          <Grid item data-testid="filterOpener">
            <Button
              color="default"
              startIcon={<FilterListIcon />}
              onClick={handleOpenPopover}
              style={{ marginTop: '12px' }}
            >
              Filter
            </Button>
          </Grid>
        )}
        {isAdminOrVolta() && (
          <Grid item>
            <Autocomplete
              id="reseller-select"
              options={resellerList}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} label="Reseller" />
              )}
              onChange={handleChangeReseller}
              disableClearable
              style={{ width: sm ? '200px' : '100%' }}
              defaultValue={() => {
                if (filter.reseller.name === EMPTY_RESELLER_OPTION) {
                  return null;
                }
                return filter.reseller;
              }}
            />
          </Grid>
        )}
        {showMonthPicker && (
          <Grid item>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                format="MMM yyyy"
                views={['year', 'month']}
                openTo="month"
                autoOk
                id="date-picker-inline"
                label="Month"
                value={filter.date}
                onChange={handleChangeDate}
                minDate={
                  reseller
                    ? calculateMinDate(reseller.createdAt)
                    : calculateMinDate(filter.reseller.createdAt)
                }
                disableFuture
                disabled={isResellerSelected()}
                style={{ width: sm ? '110px' : '100%' }}
                variant="inline"
              />
            </MuiPickersUtilsProvider>
          </Grid>
        )}
      </GridContainer>
      <Popover
        id="popover"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {popoverBody}
      </Popover>
    </>
  );
}

DashboardFilter.propTypes = {
  showDeviceType: PropTypes.bool,
  showNinetyPercentFilter: PropTypes.bool,
  showMonthPicker: PropTypes.bool,
  CustomFilterComponent: PropTypes.element,
};

export default DashboardFilter;
