export const AUTHORITIES = {
  VOLTA_ADMIN: 'VOLTA_ADMIN',
  VOLTA_USER: 'VOLTA_USER',
  RESELLER_ADMIN: 'RESELLER_ADMIN',
  RESELLER_USER: 'RESELLER_USER',
};

export const MESSAGES = {
  SERVER_ERROR:
    'An error occurred while retrieving the data, please verify it again later.',
  NO_DATA: 'No data matches your filtering criteria.',
  NO_DATA_AVAILABLE: 'No data available currently.',
  FILE_UPLOADED: 'Your file has been successfully uploaded.',
  FILE_UPLOAD_ERROR: 'An error occurred while trying to upload the file, please verify it and try again later.',
  FILE_DOWNLOADED: 'Your file has been successfully downloaded.',
  FILE_DOWNLOAD_ERROR: 'Could not download the file, please verify it and try again later.'
};

export const EMPTY_RESELLER_OPTION = 'Select a reseller';

export const PERCENTAGES = {
  lessThanTenPercent: '0% - 10%',
  lessThanTwentyFivePercent: '11% - 25%',
  lessThanFiftyPercent: '26% - 50%',
  lessThanSeventyFivePercent: '51% - 75%',
  moreThanSeventyFivePercent: '76% - 100%',
};

export const DEVICE_STATUS = {
  connected: 'Online',
  activated: 'Newly installed',
  reactivated: 'Reactivated',
  droppedOut: 'Dropped',
};
