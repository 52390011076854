import { useState, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Loader from '../../components/Loader';
import { PopoverWrapper, FormControl, ButtonGroup } from './styles';
import { ErrorText } from '../../components/typography';
import ResellersTable from './ResellersTable';
import PageTitle from '../../components/PageTitle';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
import Popover from '@material-ui/core/Popover';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';

import {
  selectIsLoading as selectIsWithoutIntegrationLoading,
  selectIsError as selectIsWithoutIntegrationError,
  selectResellersWithoutIntegrationList,
  selectResellerWithoutIntegration,
  selectEmail,
  selectValidationMessage,
  changeSelect,
  changeInput,
  clearInput,
  fetchResellersWithoutIntegration,
  integrateReseller,
  setValidationMessage,
  selectIsIntegrateLoading,
  selectIsIntegrateError,
} from './resellersAccessSlice';

import {
  selectFilteredResellerList as selectResellerWithIntegrationsList,
  fetchResellers as fetchResellersWithIntegrations,
  selectIsFilterLoading as selectIsWithIntegrationLoading,
  selectIsFilterError as selectIsWithIntegrationError,
} from '../../components/DashboardFilter/dashboardFilterSlice';
import { MESSAGES } from '../../utils/constants';

function ResellersAccess() {
  const dispatch = useDispatch();
  const [anchorAddEl, setAnchorAddEl] = useState(false);
  const [anchorConfirmationEl, setAnchorConfirmationEl] = useState(false);

  const isWithoutIntegrationLoading = useSelector(
    selectIsWithoutIntegrationLoading
  );
  const isWithoutIntegrationError = useSelector(
    selectIsWithoutIntegrationError
  );
  const resellersWithoutIntegrationList = useSelector(
    selectResellersWithoutIntegrationList
  );
  const resellerWithoutIntegration = useSelector(
    selectResellerWithoutIntegration
  );
  const email = useSelector(selectEmail);
  const validationMessage = useSelector(selectValidationMessage);

  const resellersWithIntegrationsList = useSelector(
    selectResellerWithIntegrationsList
  );
  const isWithIntegrationLoading = useSelector(selectIsWithIntegrationLoading);
  const isWithIntegrationError = useSelector(selectIsWithIntegrationError);

  const isIntegrateLoading = useSelector(selectIsIntegrateLoading);
  const isIntegrateError = useSelector(selectIsIntegrateError);

  useLayoutEffect(() => {
    if (!resellersWithoutIntegrationList) {
      dispatch(fetchResellersWithoutIntegration());
    }
    dispatch(fetchResellersWithIntegrations());
    return () => {
      dispatch(clearInput());
    };
  }, []);

  const handleChangeReseller = (e, value) => {
    dispatch(changeSelect({ name: 'resellerWithoutIntegration', value }));
  };

  const handleEmailChange = (e) => {
    dispatch(changeInput({ name: `email`, value: e.target.value }));
  };

  const isValidated = (email) => {
    const emailRegex =
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g;
    if (emailRegex.test(email) === false) {
      dispatch(setValidationMessage('Please enter a valid email address.'));
      return false;
    }
    if (
      resellersWithIntegrationsList.find(
        (reseller) => reseller.username === email
      )
    ) {
      dispatch(setValidationMessage('This email is already in use.'));
      return false;
    }
    dispatch(setValidationMessage(''));
    return true;
  };

  const handleOpenConfirmationPopover = (event) => {
    event.preventDefault();
    const lowerEmail = email.toLowerCase();

    if (isValidated(lowerEmail) === false) {
      return;
    }
    setAnchorConfirmationEl(event.currentTarget);
  };

  const handleCloseConfirmationPopover = () => {
    setAnchorConfirmationEl(null);
  };

  const handleIntegrateReseller = () => {
    dispatch(
      integrateReseller(resellerWithoutIntegration, email.toLowerCase())
    );
  };

  const popoverConfirmationBody = (
    <PopoverWrapper container style={{ width: `300px` }}>
      <Grid item>
        <Typography>
          Are you sure you want to integrate{' '}
          <strong>{resellerWithoutIntegration.name}</strong> for{' '}
          <strong>{email.toLowerCase()}</strong>?
        </Typography>
      </Grid>
      <ButtonGroup item>
        <Button
          variant="contained"
          color="primary"
          onClick={handleIntegrateReseller}
        >
          Yes
        </Button>
        <Button variant="contained" onClick={handleCloseConfirmationPopover}>
          Cancel
        </Button>
      </ButtonGroup>
    </PopoverWrapper>
  );

  const handleOpenAddPopover = (event) => {
    setAnchorAddEl(event.currentTarget);
  };

  const handleCloseAddPopover = () => {
    setAnchorAddEl(null);
  };

  const popoverAddBody = (
    <PopoverWrapper container>
      <Grid item>Add reseller</Grid>
      <Grid item>
        <form onSubmit={handleOpenConfirmationPopover}>
          <FormControl>
            <Autocomplete
              id="reseller-select"
              options={resellersWithoutIntegrationList}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} label="Reseller" />
              )}
              onChange={handleChangeReseller}
              disableClearable
              style={{ marginBottom: '10px' }}
            />
            <TextField
              id="email"
              name="email"
              placeholder="Email"
              value={email}
              onChange={handleEmailChange}
              disabled={!resellerWithoutIntegration.integratorId}
              fullWidth
              style={{ marginBottom: '10px' }}
              data-testid="email"
            />
            {validationMessage && (
              <Typography color="error">{validationMessage}</Typography>
            )}

            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!resellerWithoutIntegration.integratorId}
              fullWidth
            >
              Add reseller
            </Button>
          </FormControl>
        </form>
      </Grid>
    </PopoverWrapper>
  );

  return (
    <>
      {isWithoutIntegrationLoading && !isWithoutIntegrationError && (
        <Loader data-testid="loader" />
      )}

      {!isWithoutIntegrationLoading && isWithoutIntegrationError && (
        <ErrorText>{MESSAGES.SERVER_ERROR}</ErrorText>
      )}

      {!isWithoutIntegrationLoading &&
        !isWithoutIntegrationError &&
        resellersWithoutIntegrationList && (
          <>
            <PageTitle title="Resellers access">
              <Button
                color="default"
                startIcon={<AddIcon />}
                onClick={handleOpenAddPopover}
                style={{ marginTop: '12px' }}
              >
                Add
              </Button>
            </PageTitle>

            {(isWithIntegrationLoading || isIntegrateLoading) &&
              !(isWithIntegrationError || isIntegrateError) && (
                <Loader data-testid="loader" />
              )}

            {!(isWithIntegrationLoading || isIntegrateLoading) &&
              (isWithIntegrationError || isIntegrateError) && (
                <ErrorText>{MESSAGES.SERVER_ERROR}</ErrorText>
              )}

            {!(isWithIntegrationLoading || isIntegrateLoading) &&
              !(isWithIntegrationError || isIntegrateError) &&
              resellersWithIntegrationsList && (
                <ResellersTable resellers={resellersWithIntegrationsList} />
              )}
          </>
        )}

      <Popover
        id="add-popover"
        open={Boolean(anchorAddEl)}
        anchorEl={anchorAddEl}
        onClose={handleCloseAddPopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {popoverAddBody}
      </Popover>

      <Popover
        id="confirmation-popover"
        open={Boolean(anchorConfirmationEl)}
        anchorEl={anchorConfirmationEl}
        onClose={handleCloseConfirmationPopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {popoverConfirmationBody}
      </Popover>
    </>
  );
}

export default ResellersAccess;
