import PropTypes from 'prop-types';

import { NavLink } from 'react-router-dom';
import AppsIcon from '@material-ui/icons/Apps';
import BlurOnIcon from '@material-ui/icons/BlurOn';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
// import CloudUploadIcon from '@material-ui/icons/CloudUpload';
// import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import NestedGroup from './components/NestedGroup';
import useAuthorities from '../../../../hooks/useAuthorities';
import {
  Wrapper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SubHeader,
} from './styles';

function Navigation({ closeMobilebar = null, isSidebarOpen = null }) {
  const { isAdmin } = useAuthorities();

  return (
    <Wrapper>
      <List
        component="div"
        aria-labelledby="navigation-list"
        subheader={
          (closeMobilebar || isSidebarOpen) && (
            <SubHeader>Appliance Breakdown</SubHeader>
          )
        }
      >
        <ListItem
          component={ NavLink }
          to="/overview"
          onClick={ closeMobilebar }
          title="Overview"
          $isSidebarOpen={ closeMobilebar || isSidebarOpen }
        >
          <ListItemIcon>
            <AppsIcon />
          </ListItemIcon>
          { (closeMobilebar || isSidebarOpen) && (
            <ListItemText primary="Overview" />
          ) }
        </ListItem>
        <NestedGroup
          closeMobilebar={ closeMobilebar }
          isSidebarOpen={ isSidebarOpen }
        />
        <ListItem
          component={ NavLink }
          to="/non-disaggregated"
          onClick={ closeMobilebar }
          title="Non-disaggregated Load"
          $isSidebarOpen={ closeMobilebar || isSidebarOpen }
        >
          <ListItemIcon>
            <BlurOnIcon />
          </ListItemIcon>
          { (closeMobilebar || isSidebarOpen) && (
            <ListItemText primary="Non-disaggregated Load" />
          ) }
        </ListItem>
        <ListItem
          component={ NavLink }
          to="/data-quality"
          onClick={ closeMobilebar }
          title="Data Quality"
          $isSidebarOpen={ closeMobilebar || isSidebarOpen }
        >
          <ListItemIcon>
            <BeenhereIcon />
          </ListItemIcon>
          { (closeMobilebar || isSidebarOpen) && (
            <ListItemText primary="Data Quality" />
          ) }
        </ListItem>
      </List>
      <List
        component="nav"
        aria-labelledby="navigation-list"
        subheader={
          (closeMobilebar || isSidebarOpen) && (
            <SubHeader>Subscription</SubHeader>
          )
        }
        title="Subscription Overview"
      >
        <ListItem
          component={ NavLink }
          to="/subscription"
          onClick={ closeMobilebar }
          $isSidebarOpen={ closeMobilebar || isSidebarOpen }
        >
          <ListItemIcon>
            <EqualizerIcon />
          </ListItemIcon>
          { (closeMobilebar || isSidebarOpen) && (
            <ListItemText primary="Overview" />
          ) }
        </ListItem>
      </List>

      {/* <List
        component="nav"
        aria-labelledby="navigation-list"
        subheader={
          (closeMobilebar || isSidebarOpen) && (
            <SubHeader>Cost &amp; Consumption</SubHeader>
          )
        }
        title="Cost &amp; Consumption"
      >
        <ListItem
          component={NavLink}
          to="/cost-consumption-upload"
          onClick={closeMobilebar}
          title="Upload"
          $isSidebarOpen={closeMobilebar || isSidebarOpen}
        >
          <ListItemIcon>
            <CloudUploadIcon />
          </ListItemIcon>
          {(closeMobilebar || isSidebarOpen) && (
            <ListItemText primary="Upload" />
          )}
        </ListItem>
        <ListItem
          component={NavLink}
          to="/cost-consumption-results"
          onClick={closeMobilebar}
          title="Results"
          $isSidebarOpen={closeMobilebar || isSidebarOpen}
        >
          <ListItemIcon>
            <CloudDownloadIcon />
          </ListItemIcon>
          {(closeMobilebar || isSidebarOpen) && (
            <ListItemText primary="Results" />
          )}
        </ListItem>
      </List> */}

      { isAdmin() && (
        <List
          component="nav"
          aria-labelledby="navigation-list"
          subheader={
            (closeMobilebar || isSidebarOpen) && (
              <SubHeader>Data Validation</SubHeader>
            )
          }
        >
          <ListItem
            component={ NavLink }
            to="/packet-based-messages"
            onClick={ closeMobilebar }
            $isSidebarOpen={ closeMobilebar || isSidebarOpen }
            title="Packet-based Messages"
          >
            <ListItemIcon>
              <MailOutlineIcon />
            </ListItemIcon>
            { (closeMobilebar || isSidebarOpen) && (
              <ListItemText primary="Packet-based Messages" />
            ) }
          </ListItem>
        </List>
      ) }
    </Wrapper>
  );
}

Navigation.propTypes = {
  closeMobilebar: PropTypes.func,
  isSidebarOpen: PropTypes.bool,
};

export default Navigation;
