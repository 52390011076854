import styled from 'styled-components';

export const Wrapper = styled.div`
  & .MuiSlider-colorPrimary {
    color: #388e3c;
  }

  & .MuiSlider-markLabel {
    color: #bbb;
    font-size: 0.8em;
  }

  & .MuiSlider-markLabelActive {
    color: #388e3c;
  }

  & .MuiSlider-mark {
    display: none;
  }

  & .MuiSlider-valueLabel {
    z-index: 0;

    & > span {
      background: none;
      height: 70px;

      & > span {
        color: #388e3c !important;
      }
    }
  }

  & .MuiSlider-rail {
    opacity: 0.5;
  }
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 3px;
  font-size: 0.9em;
`;

export const Input = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  margin-left: 5px;
  margin-top: 10px;
`;
