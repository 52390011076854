import { createSlice } from '@reduxjs/toolkit';
import API from '../../api';
import { MESSAGES } from '../../utils/constants';

export const dataQualitySlice = createSlice({
  name: 'dataQuality',
  initialState: {
    isLoading: false,
    isError: false,
    errorMessage: MESSAGES.SERVER_ERROR,
    data: null,
  },
  reducers: {
    fetchStart: (state) => {
      state.isLoading = true;
      state.isError = false;
    },
    fetchSuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    fetchError: (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
      state.isError = true;
    },
    clearData: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.errorMessage = MESSAGES.SERVER_ERROR;
      state.data = null;
    },
  },
});

export const {
  fetchStart,
  fetchSuccess,
  fetchError,
  clearData,
} = dataQualitySlice.actions;

export const fetchData = (date, integratorId, deviceType) => async (dispatch) => {
  try {
    dispatch(fetchStart());

    const response = await API.DATA_QUALITY(date, integratorId, deviceType);

    if (response.data.numberOfDevices === null) {
      return dispatch(fetchError(MESSAGES.NO_DATA));
    }

    dispatch(fetchSuccess(response.data));
  } catch (err) {
    if (err.response && err.response.status === 404) {
      return dispatch(fetchError(MESSAGES.NO_DATA));
    }
    dispatch(fetchError(MESSAGES.SERVER_ERROR));
  }
};

export const selectIsLoading = (state) => state.dataQuality.isLoading;
export const selectIsError = (state) => state.dataQuality.isError;
export const selectErrorMessage = (state) => state.dataQuality.errorMessage;
export const selectData = (state) => state.dataQuality.data;

export default dataQualitySlice.reducer;
