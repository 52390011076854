import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import Collapse from '@material-ui/core/Collapse';
import KitchenIcon from '@material-ui/icons/Kitchen';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Popover from '@material-ui/core/Popover';
import MenuItem from '@material-ui/core/MenuItem';

import { List, ListItem, ListItemIcon, ListItemText } from '../../styles';
import { NestedListItem, PopoverWrapper } from './styles';

function NestedGroup({ closeMobilebar = null, isSidebarOpen = null }) {
  const [open, setOpen] = useState(false);

  const handleClick = (event) => {
    if (isSidebarOpen === false) {
      handleMenuOpen(event);
      return;
    }
    setOpen(!open);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  useEffect(() => {
    if (isSidebarOpen === false) {
      setOpen(false);
    }
  }, [isSidebarOpen]);

  return (
    <>
      <ListItem
        component="button"
        onClick={handleClick}
        title="Appliances"
        $isSidebarOpen={closeMobilebar || isSidebarOpen}
      >
        <ListItemIcon>
          <KitchenIcon />
        </ListItemIcon>
        {(closeMobilebar || isSidebarOpen) && (
          <ListItemText primary="Appliances" />
        )}
        {open
          ? (closeMobilebar || isSidebarOpen) && <ExpandLess fontSize="small" />
          : (closeMobilebar || isSidebarOpen) && (
              <ExpandMore fontSize="small" />
            )}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <NestedListItem
            component={NavLink}
            to="/reported"
            onClick={closeMobilebar}
          >
            <ListItemText primary="Reported by Users" />
          </NestedListItem>
          <NestedListItem
            component={NavLink}
            to="/disaggregated"
            onClick={closeMobilebar}
          >
            <ListItemText primary="Disaggregated" />
          </NestedListItem>
        </List>
      </Collapse>
      <Popover
        id="popover"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <PopoverWrapper>
          <MenuItem
            to="/reported"
            component={NavLink}
            onClick={handleMenuClose}
          >
            Reported by Users
          </MenuItem>
          <MenuItem
            to="/disaggregated"
            component={NavLink}
            onClick={handleMenuClose}
          >
            Disaggregated
          </MenuItem>
        </PopoverWrapper>
      </Popover>
    </>
  );
}

NestedGroup.propTypes = {
  closeMobilebar: PropTypes.func,
  isSidebarOpen: PropTypes.bool,
};

export default NestedGroup;
