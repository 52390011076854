import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Wrapper, FixedComponent } from './styles';
import Menu from '../Menu';
import { selectIsSidebarOpen, changeOpenSidebar } from './sidebarSlice';

function Sidebar() {
  const isSidebarOpen = useSelector(selectIsSidebarOpen);
  const dispatch = useDispatch();

  const persistSidebarState = () => {
    localStorage.setItem('sidebar', JSON.stringify(isSidebarOpen));
  };

  useEffect(() => {
    window.addEventListener('beforeunload', persistSidebarState);

    return () => {
      window.removeEventListener('beforeunload', persistSidebarState);
    };
  }, [persistSidebarState]);

  const handleOpenSidebar = (state) => {
    dispatch(changeOpenSidebar(state));
  };

  return (
    <Wrapper isSidebarOpen={isSidebarOpen}>
      <FixedComponent isSidebarOpen={isSidebarOpen}>
        <Menu
          isSidebarOpen={isSidebarOpen}
          handleOpenSidebar={handleOpenSidebar}
        />
      </FixedComponent>
    </Wrapper>
  );
}

export default Sidebar;
