import { createSlice } from '@reduxjs/toolkit';

import { clearFilter } from '../../components/DashboardFilter/dashboardFilterSlice';

import API from '../../api';
import {
  getToken,
  setToken,
  removeToken,
  getAuthorities,
  setAuthorities,
  removeAuthorities,
  getCurrentUser,
  setCurrentUser,
  removeCurrentUser,
  getReseller,
  setReseller,
  removeReseller,
} from '../../api/auth';

export const loginSlice = createSlice({
  name: 'login',
  initialState: {
    username: '',
    password: '',
    isLoading: false,
    isLoginError: false,
    errors: {
      username: true,
      password: true,
    },
    clicked: {
      username: false,
      password: false,
    },
    token: getToken(),
    authorities: getAuthorities(),
    currentUser: getCurrentUser(),
    reseller: getReseller() || null,
  },
  reducers: {
    changeInput: (state, action) => {
      state[action.payload.name] = action.payload.value;
      if (state[action.payload.name] === '') {
        state.errors[action.payload.name] = true;
      } else {
        state.errors[action.payload.name] = false;
      }
    },
    toogleInput: (state, action) => {
      state.clicked[action.payload] = true;
    },
    loginStart: (state) => {
      state.isLoading = true;
      state.isLoginError = false;
    },
    loginSuccess: (state, action) => {
      state.isLoading = false;
      state.isLoginError = false;
      state.token = action.payload.token;
      state.authorities = action.payload.authorities;
      state.currentUser = action.payload.currentUser;
      if (action.payload.reseller) {
        state.reseller = action.payload.reseller;
      }

      state.username = '';
      state.password = '';
      state.errors = {
        username: true,
        password: true,
      };
      state.clicked = {
        username: false,
        password: false,
      };
    },
    loginError: (state) => {
      state.isLoading = false;
      state.isLoginError = true;
      state.password = '';
      state.errors.password = true;
    },
    logoutSuccess: (state) => {
      state.token = null;
      state.authorities = null;
      state.currentUser = null;
      state.reseller = null;
    },
  },
});

export const {
  changeInput,
  toogleInput,
  loginStart,
  loginSuccess,
  loginError,
  logoutSuccess,
} = loginSlice.actions;

export const login = (username, password) => async (dispatch) => {
  try {
    dispatch(loginStart());
    const response = await API.LOGIN(username, password);

    setToken(response.headers['x-auth-token']);
    setAuthorities(response.data.authorities);
    setCurrentUser(response.data.username);
    if (response.data.reseller) {
      setReseller(response.data.reseller);
    }

    dispatch(clearFilter());
    dispatch(
      loginSuccess({
        token: response.headers['x-auth-token'],
        authorities: response.data.authorities,
        currentUser: username,
        reseller: response.data.reseller || null,
      })
    );
  } catch (error) {
    dispatch(loginError());
  }
};

export const logout = () => (dispatch) => {
  try {
    API.LOGOUT();
  } catch (error) {}

  removeToken();
  removeAuthorities();
  removeCurrentUser();
  removeReseller();
  dispatch(logoutSuccess());

  dispatch(clearFilter());
};

export const selectUserName = (state) => state.login.username;
export const selectPassword = (state) => state.login.password;
export const selectIsLoading = (state) => state.login.isLoading;
export const selectIsLoginError = (state) => state.login.isLoginError;
export const selectErrors = (state) => state.login.errors;
export const selectClicked = (state) => state.login.clicked;
export const selectIsAuth = (state) => !!state.login.token;
export const selectAuthorities = (state) => state.login.authorities;
export const selectCurrentUser = (state) => state.login.currentUser;
export const selectReseller = (state) => state.login.reseller;

export default loginSlice.reducer;
