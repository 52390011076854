import styled from 'styled-components';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';

export const CustomTableCell = styled(TableCell)`
  ${({ $showElectricReadings }) => $showElectricReadings && 'border: unset;'}
`;

export const SubtableCell = styled(TableCell).attrs({
  colSpan: 15,
})`
  padding-top: 0;
  padding-bottom: 0;
`;

export const SubtableWrapper = styled(Paper)`
  margin-top: 20px;
  margin-bottom: 20px;
`;
