import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const PopoverWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
  padding: 10px;
  overflow-y: hidden;
`;

export const GridContainer = styled(Grid)`
  display: flex;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;
