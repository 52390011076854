import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
import Grid from '@material-ui/core/Grid';

import Card from '../../../components/Card';
import { parseNumberToString } from '../../../utils/parseData';

const StyledGrid = styled(Grid)`
  margin-bottom: 10px;
`;
function DataQualityCards({ data }) {
  const props = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
  });
  const AnimatedGrid = animated(StyledGrid);

  return (
    <AnimatedGrid container spacing={2} style={props}>
      <Card
        xs={12}
        md={3}
        title="Low activity"
        data={
          parseNumberToString(
            data.lowActivityPercentage && data.lowActivityPercentage.percentage,
            1
          ) + '%'
        }
        details={parseNumberToString(
          data.lowActivityPercentage &&
            data.lowActivityPercentage.numberOfDevices
        )}
      />
      <Card
        xs={12}
        md={3}
        title="Property not provided"
        data={
          parseNumberToString(
            data.propertyInformationNotFiledPercentage &&
              data.propertyInformationNotFiledPercentage.percentage,
            1
          ) + '%'
        }
        details={parseNumberToString(
          data.propertyInformationNotFiledPercentage &&
            data.propertyInformationNotFiledPercentage.numberOfDevices
        )}
        tooltip="This number indicates the percentage of devices that does not have property information of their “house”."
      />
      <Card
        xs={12}
        md={3}
        title="Appliances not provided"
        data={
          parseNumberToString(
            data.appliancesInformationNotProvidedPercentage &&
              data.appliancesInformationNotProvidedPercentage.percentage,
            1
          ) + '%'
        }
        details={parseNumberToString(
          data.appliancesInformationNotProvidedPercentage &&
            data.appliancesInformationNotProvidedPercentage.numberOfDevices
        )}
        tooltip="Devices that the user does not have informed appliances, in this counter is also included devices that does not have property information."
      />
      <Card
        xs={12}
        md={3}
        title="Number of devices"
        data={parseNumberToString(data.numberOfDevices)}
      />
    </AnimatedGrid>
  );
}

DataQualityCards.propTypes = {
  data: PropTypes.object.isRequired,
};

export default DataQualityCards;
