import styled from 'styled-components';
import MUIAppBar from '@material-ui/core/AppBar';
import MUIMenu from '@material-ui/core/Menu';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

export const AppBar = styled(MUIAppBar)`
  background: #fff;
  border: none;
  box-shadow: none;

  & > div {
    justify-content: flex-end;

    & button {
      opacity: 0.7;
    }
  }
`;

export const Menu = styled(MUIMenu)`
  & > .MuiPaper-root {
    & a,
    li {
      font-size: 0.9em;
      opacity: 0.87;
    }
  }
`;

export const ResellerName = styled(Typography)`
  color: #000;
  opacity: 0.87;
  margin-right: 20px;
  @media (max-width: 600px) {
    font-size: 1em;
  }
`;

export const MenuButton = styled(IconButton)`
  margin-right: auto;
`;
